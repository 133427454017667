import { useState, useEffect } from "react";
import { GetLeads } from "../../actions/leads";
import { useNavigate } from "react-router";
import { GetAllSubmissions } from "../../actions/submissions";
import dayjs from "dayjs";

const SubmissionsList = () => {
  const [loading, setLoading] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [leads, setLeads] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllSubmissions()
        .then((res) => {
          setSubmissions(res.data.submissions);
          setWidgets(res.data.widgets);
          setLeads(res.data.leads);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          //
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const formatPhoneNumber = (phone) => {
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length === 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return phone;
  };

  const renderSubmission = (submission) => {
    let lead = leads.find((lead) => lead.leadId === submission.leadId);
    return (
      <tr>
        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap dark:text-gray-200">{dayjs(submission.startedOn).format("MM/DD/YYYY")}</td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap dark:text-gray-200">
          {!lead ? "Unknown" : lead.name && lead.name.length > 0 ? lead.name : lead.phone && lead.phone.length > 0 ? formatPhoneNumber(lead.phone) : "Unknown"}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap dark:text-gray-200">
          {submission.completed ? "Completed" : dayjs(submission.startedOn).isBefore(dayjs().subtract(30, "minutes")) ? "Abandoned" : "In Progress"}
        </td>
        <td className="px-6 py-4 text-sm font-medium whitespace-nowrap text-end">
          <button
            onClick={() => navigate(`/submissions/${submission.submissionId}`)}
            className="inline-flex items-center text-sm font-semibold text-blue-600 border border-transparent rounded-lg gap-x-2 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            View
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full max-h-screen pl-4 sm:pl-6 md:pl-8 lg:pl-64">
      <div className="flex flex-row items-center justify-between w-full px-8 py-4 border-b border-gray-300">
        <p className="text-lg font-bold text-black">Submissions List</p>
      </div>
      {!loading ? (
        <div className="flex flex-col w-full p-10">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle bg-white rounded-xl border border-gray-200">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className="border-b border-gray-300">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                        Date Started
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                        Lead
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-end"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">{submissions.map((submission) => renderSubmission(submission))}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-10">
          <p className="font-medium text-gray-500 uppercase animate-pulse">Loading Submissions...</p>
        </div>
      )}
    </div>
  );
};

export default SubmissionsList;
