import { useEffect, useState } from "react";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import { ShieldQuestion } from "iconoir-react";
import { Loader2 } from "lucide-react";
import { GetAllWidgets } from "../../../actions/api";
import toast from "react-hot-toast";

const Widgets = () => {
  const [loading, setLoading] = useState(true);
  const [widgets, setWidgets] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllWidgets()
        .then((res) => {
          setWidgets(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving your widgets.");
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const tabs = [
    { name: "Details", href: "/account", current: false },
    { name: "Payments", href: "/account/payments", current: false },
    { name: "Widgets", href: "/account/widgets", current: true },
    { name: "Integrations", href: "/account/integrations", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderLine = (data) => {
    return (
      <tr className="hover:bg-gray-100" key={data.widgetId}>
        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{data.title}</td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{data.formStatus}</td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{data.chatEnabled ? "Yes" : "No"}</td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{data.ctaCard ? "Yes" : "No"}</td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{data.popup ? "Yes" : "No"}</td>
        <td className="px-6 py-4 text-sm font-medium whitespace-nowrap text-end">
          <button
            type="button"
            className="inline-flex items-center text-sm font-semibold text-blue-600 border border-transparent rounded-lg gap-x-2 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => navigate("/account/widgets/" + data.widgetId)}
          >
            Open
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full max-h-screen gap-4 px-4 pt-10 sm:px-6 md:px-8 lg:pl-72">
      <div className="w-full pb-5 border-b border-gray-200 sm:pb-0">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-xl font-bold leading-6 text-gray-900">Your Widgets</h3>
          <Button type="secondary" label="Create New Widget" onClick={() => navigate("/account/widgets/new")} />
        </div>
        <div className="mt-3 sm:mt-5">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              {tabs.map((tab) => (
                <p
                  key={tab.name}
                  onClick={() => navigate(tab.href)}
                  className={classNames(
                    tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-950",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </p>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center flex-grow gap-4 overflow-y-auto content">
          <Loader2 className="w-10 h-10 text-gray-400 animate-spin" />
          <p className="text-sm font-medium text-gray-500 uppercase animate-pulse">Retrieving Widgets</p>
        </div>
      ) : (
        <div className="flex flex-col flex-grow overflow-y-auto content">
          {widgets.length > 0 ? (
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                          Chat Enabled
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                          CTA Card Enabled
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">
                          Popup Enabled
                        </th>
                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-end">
                          <span className="sr-only">View/Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">{widgets.map((widget) => renderLine(widget))}</tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center flex-grow w-full h-full gap-1">
              <ShieldQuestion className="w-10 h-10 text-gray-700" />
              <h3 className="mt-3 text-xl font-semibold">You don't have any widgets yet</h3>
              <p className="text-sm text-gray-600">Once you create a widget, it will appear here.</p>
              <Button type="dark" label="Create New Widget" onClick={() => navigate("/account/widgets/new")} className="mt-8" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Widgets;
