import axiosInstance from "./axios";

export const GetLeads = () => {
  return axiosInstance.get(`/leads`);
};

export const GetLead = (leadId) => {
  return axiosInstance.get(`/leads/${leadId}`);
};

export const AddLeadNote = (leadId, data) => {
  return axiosInstance.put(`/leads/${leadId}/note`, data);
};
