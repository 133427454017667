import { useNavigate } from "react-router-dom";

const AccountHome = () => {
  const navigate = useNavigate();
  const tabs = [
    { name: "Details", href: "/account", current: true },
    { name: "Payments", href: "/account/payments", current: false },
    { name: "Widgets", href: "/account/widgets", current: false },
    { name: "Integrations", href: "/account/integrations", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="flex flex-col items-center justify-start w-full h-full px-4 pt-10 sm:px-6 md:px-8 lg:pl-72">
      <div className="w-full pb-5 border-b border-gray-200 sm:pb-0">
        <h3 className="text-xl font-bold leading-6 text-gray-900">Account Information</h3>
        <div className="mt-3 sm:mt-5">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              {tabs.map((tab) => (
                <p
                  key={tab.name}
                  onClick={() => navigate(tab.href)}
                  className={classNames(
                    tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-950",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </p>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountHome;
