import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Dashboard from "../views/dashboard";
import Wrapper from "../components/wrapper";
import MessagesLeadId from "../views/messages/leadId";
import LeadsList from "../views/messages";
import NewAutomation from "../views/automations/new";
import AccountHome from "../views/account/index";
import Widgets from "../views/account/widgets";
import NewWidget from "../views/account/widgets/newWidget";
import OpenWidget from "../views/account/widgets/openWidget";
import SubmissionsList from "../views/submissions";
import OpenSubmission from "../views/submissions/submissionId";

const CoreLayout = ({ authState, authDispatch }) => {
  return (
    <Routes>
      <Route path="*" element={<Wrapper authState={authState} authDispatch={authDispatch} />}>
        <Route index element={<Dashboard />} />
        <Route path="messages/" element={<LeadsList />} />
        <Route path="messages/:leadId" element={<MessagesLeadId />} />
        <Route path="automation/new" element={<NewAutomation />} />
        <Route path="account" element={<AccountHome />} />
        <Route path="account/widgets" element={<Widgets />} />
        <Route path="account/widgets/new" element={<NewWidget />} />
        <Route path="account/widgets/:widgetId" element={<OpenWidget />} />
        <Route path="submissions" element={<SubmissionsList />} />
        <Route path="submissions/:submissionId" element={<OpenSubmission />} />
      </Route>
    </Routes>
  );
};

export default CoreLayout;
