import { useState, useEffect } from "react";
import { Coins, Group, Message, MultiplePages, NavArrowRight, Percentage } from "iconoir-react";
import dayjs from "dayjs";
import { GetDashboard } from "../actions/auth";
import toast from "react-hot-toast";
import { formatCurrency } from "../components/utils";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetDashboard()
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading dashboard data");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const people = [
    {
      name: "Lindsay Walton",
      message: "Can you send me a purchase order for this car please?",
      id: "lindsay.walton@example.com",
      lastCalled: "2023-10-19T00:59:52Z",
    },
    { name: "William Johnston", message: "I'll be there around 4PM", id: "will.johnston@example.com", lastCalled: "2023-10-19T04:32:30Z" },
  ];

  const calculateConversionRate = () => {
    if (loading) {
      return "---";
    } else {
      let total = data.submissions.length;
      let completed = data.submissions.filter((s) => s.completed).length;
      let rate = (completed / total) * 100;
      return rate.toFixed(0) + "%";
    }
  };

  const calculateRevenue = () => {
    if (loading) {
      return "---";
    } else {
      let total = 0;
      let completed = data.submissions.filter((s) => s.payment && s.payment && s.payment.amount > 0);
      completed.forEach((s) => {
        total += s.payment.amount;
      });
      return formatCurrency(total);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full px-4 pt-10 sm:px-6 md:px-8 lg:pl-72">
      <div className="w-full px-4 pt-10 pb-5 mx-auto sm:px-6 lg:px-8 lg:pt-8 lg:pb-4">
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4 sm:gap-6">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex items-center justify-between p-4 md:p-5 gap-x-3">
              <div>
                <p className="text-xs tracking-wide text-gray-500 uppercase">Leads</p>

                <h3 className="mt-1 text-xl font-medium text-gray-800 dark:text-gray-200">{loading ? "---" : data.leads.length}</h3>
              </div>
              <div className="flex-shrink-0 flex justify-center items-center w-[36px] h-[36px] text-blue-600 border border-blue-600 rounded-full">
                <Group className="w-5 h-5" />
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex items-center justify-between p-4 md:p-5 gap-x-3">
              <div>
                <p className="text-xs tracking-wide text-gray-500 uppercase">Submissions</p>

                <h3 className="mt-1 text-xl font-medium text-gray-800 dark:text-gray-200">{loading ? "---" : data.submissions.length}</h3>
              </div>
              <div className="flex-shrink-0 flex justify-center items-center w-[36px] h-[36px] text-blue-600 border border-blue-600 rounded-full">
                <MultiplePages className="w-5 h-5" />
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex items-center justify-between p-4 md:p-5 gap-x-3">
              <div>
                <p className="text-xs tracking-wide text-gray-500 uppercase">Conversion Rate</p>

                <h3 className="text-xl font-medium text-gray-800 sm:text-2xl dark:text-gray-200">{calculateConversionRate()}</h3>
              </div>
              <div className="flex-shrink-0 flex justify-center items-center w-[36px] h-[36px] text-blue-600 border border-blue-600 rounded-full">
                <Percentage className="w-5 h-5" />
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-white border shadow-sm rounded-xl">
            <div className="flex items-center justify-between p-4 md:p-5 gap-x-3">
              <div>
                <p className="text-xs tracking-wide text-gray-500 uppercase">Revenue</p>

                <h3 className="mt-1 text-xl font-medium text-gray-800 dark:text-gray-200">{calculateRevenue()}</h3>
              </div>
              <div className="flex-shrink-0 flex justify-center items-center w-[34px] h-[34px] text-blue-600 border border-blue-600 rounded-full">
                <Coins className="w-5 h-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-wrap items-start justify-between w-full gap-5 px-4 mx-auto lg:flex-row xl:flex-nowrap sm:px-6 lg:px-8">
        <div className="w-full mx-auto bg-white border shadow-sm max-w-7xl rounded-xl">
          <div className="py-5">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-black">Submissions</h1>
                </div>
              </div>
              <div className="flow-root mt-3">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-black sm:pl-0">
                            Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-black">
                            Status
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Open</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        {people.map((person) => (
                          <tr key={person.email}>
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-black whitespace-nowrap sm:pl-0">{person.name}</td>
                            <td className="px-3 py-4 text-sm text-gray-700 truncate whitespace-nowrap">{person.message}</td>
                            <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                              <button className="text-blue-600 hover:text-blue-500">
                                Open<span className="sr-only">, {person.name}</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mx-auto bg-white border shadow-sm max-w-7xl rounded-xl">
          <div className="py-5">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-black">Messages</h1>
                </div>
              </div>
              <div className="flow-root mt-3">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-black sm:pl-0">
                            Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-black">
                            Last Message
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Open</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        {people.map((person) => (
                          <tr key={person.email}>
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-black whitespace-nowrap sm:pl-0">{person.name}</td>
                            <td className="px-3 py-4 text-sm text-gray-700 truncate whitespace-nowrap">{dayjs(person.lastCalled).fromNow()}</td>
                            <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                              <button className="text-blue-600 hover:text-blue-500">
                                Open<span className="sr-only">, {person.name}</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
