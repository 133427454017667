import { useState, useEffect, useRef } from "react";
import { Button, Dropdown, Input, Popover } from "antd";
import { ArrowLeft, Attachment, Clock, EmojiBlinkLeft, Phone, SendDiagonal, ViewGrid } from "iconoir-react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import validator from "validator";
import OpengraphReactComponent from "opengraph-react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const Chatbox = ({ leadData, membersData, socket, initialMessages }) => {
  const [loading, setLoading] = useState(false);
  const [lead, setLead] = useState(leadData);
  const [members, setMembers] = useState(membersData);
  const [messages, setMessages] = useState(initialMessages ? initialMessages : []);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const chatBottom = useRef(null);

  const renderMessage = (message) => {
    let isLink = validator.isURL(message.body);
    if (message.sender === lead.phone) {
      return (
        <div className="flex flex-col items-start justify-center w-full gap-1 mb-4">
          {!isLink ? (
            <>
              <p className="ml-2 w-fit max-w-[24rem] rounded-br-xl rounded-tl-xl rounded-tr-xl bg-blue-500 px-4 py-3 text-white">{message.body}</p>
              <p className="pl-2 text-xs text-gray-500">
                {lead.name ? lead.name : lead.phone} at {dayjs(message.timestamp).format("h:m a")} ({dayjs(message.timestamp).format("MM/DD/YY")})
              </p>
            </>
          ) : (
            <div
              className="px-4 py-3 ml-2 text-white bg-blue-500 cursor-pointer w-fit rounded-br-xl rounded-tl-xl rounded-tr-xl"
              onClick={() => window.open(message.body, "_blank")}
            >
              <OpengraphReactComponent
                site={message.body}
                appId="05d609c6-db39-475d-a2ef-a1bfda600c18"
                onlyFetch={false}
                size={"small"}
                onClick={() => window.open(message.body, "_blank")}
              />
              <p className="mt-2 text-xs text-gray-500">
                {lead.name ? lead.name : lead.phone} at {dayjs(message.timestamp).format("h:m a")} ({dayjs(message.timestamp).format("MM/DD/YY")})
              </p>
            </div>
          )}
        </div>
      );
    } else {
      let sender = members.find((member) => member.userId === message.sender);
      let name = "Unknown Sender";
      if (sender) {
        name = sender.name;
      }
      return (
        <div className="flex flex-col items-end justify-center gap-1 mb-4">
          {!isLink ? (
            <>
              <p className="ml-2 w-fit max-w-[24rem] rounded-bl-xl rounded-tl-xl rounded-tr-xl bg-gray-200 px-4 py-3 text-black ">{message.body}</p>
              <p className="pl-2 text-xs text-gray-500">
                {name} at {dayjs(message.timestamp).format("h:m a")} ({dayjs(message.timestamp).format("MM/DD/YY")})
              </p>
            </>
          ) : (
            <>
              <div
                className="px-4 py-3 ml-2 text-black bg-gray-200 cursor-pointer w-fit rounded-bl-xl rounded-tl-xl rounded-tr-xl senderLink"
                onClick={() => window.open(message.body, "_blank")}
              >
                <OpengraphReactComponent
                  site={message.body}
                  appId="05d609c6-db39-475d-a2ef-a1bfda600c18"
                  onlyFetch={false}
                  size={"small"}
                  onClick={() => window.open(message.body, "_blank")}
                  className="text-black bg-white"
                />
              </div>
              <p className="pl-2 text-xs text-gray-500">
                {name} at {dayjs(message.timestamp).format("h:m a")} ({dayjs(message.timestamp).format("MM/DD/YY")})
              </p>
            </>
          )}
        </div>
      );
    }
  };

  socket.on("new_message", (data) => {
    setMessages([...messages, data]);
  });

  socket.on("message_sent", (data) => {
    toast.success(data.message, { id: "message_sent" });
  });

  socket.on("error_sending_message", (data) => {
    toast.error(data.message, { id: "error_sending_message" });
    console.log("Received Error");
  });

  const sendMessage = () => {
    if (message.length > 0) {
      socket.emit("submit_new_message", { message: message });
      setMessage("");
    } else {
      toast.error("Please enter a message.", { id: "no_message" });
    }
  };

  const scrollToBottom = () => {
    chatBottom.current?.scrollIntoView({ behavior: "instant" });
  };
  const onBeforeUnload = (e) => {
    socket.emit("leave_messages", { message: "Disconnecting..." });
    socket.disconnect();
  };
  window.addEventListener("beforeunload", onBeforeUnload);

  useEffect(() => {
    if (!loading) {
      scrollToBottom();
    }
  }, [loading]);

  const EmojiPicker = () => {
    return <Picker data={data} onEmojiSelect={selectedEmoji} previewPosition="none" theme="light" skinTonePosition="search" />;
  };

  const selectedEmoji = (data) => {
    setMessage(message + data.native);
  };

  const items = [
    {
      key: "paymentLink",
      label: <p className="text-center">Payment Link</p>,
    },
    // {
    //   key: "appointment",
    //   label: <p className="text-center">Appointment</p>,
    // },
    {
      key: "reviewRequest",
      label: <p className="text-center">Review Request</p>,
    },
  ];

  const actionItemClick = (key) => {
    if (key === "paymentLink") {
      //
    } else if (key === "appointment") {
      //
    } else if (key === "reviewRequest") {
      //
    }
  };

  return (
    <div className="flex flex-col items-center justify-between h-screen col-span-9 bg-white">
      <div className="flex flex-row items-center justify-between w-full px-5 py-4 border-b border-gray-200 bg-gray-50">
        <Button className="flex items-center justify-center gap-1 px-3 font-semibold tracking-wide" type="primary" onClick={() => navigate(-1)}>
          <ArrowLeft className="w-4 h-4 text-white" />
        </Button>
        <p>{lead.name && lead.name.length > 0 ? lead.name : lead.phone}</p>
        <Button className="px-1 font-semibold tracking-wide" type="primary" onClick={() => window.open(`tel:${lead.phone}`)}>
          <Phone className="w-5 h-5 text-white" />
        </Button>
      </div>
      <div className="flex flex-col flex-grow w-full px-5 py-2 mt-0 overflow-y-auto">
        {messages.map((message) => renderMessage(message))}
        <div ref={chatBottom} />
      </div>
      <div className="flex flex-row items-center justify-between w-full gap-4 px-5 py-4 border-t border-gray-200 bg-gray-50 h-fit chatboxControls">
        {/* Appointment */}
        {/* <Button className="px-1 font-semibold tracking-wide" type="primary">
          <Clock className="w-5 h-5 text-white" />
        </Button> */}
        {/* <Dropdown placement="top" menu={{ actionItems }}> */}
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => actionItemClick(key),
          }}
          placement="top"
        >
          <Button className="px-1 font-semibold tracking-wide" type="primary">
            <ViewGrid className="w-5 h-5 text-white" />
          </Button>
        </Dropdown>
        <Input
          className="flex-grow py-2 border-gray-600 outline-none focus:shadow-none placeholder:text-gray-500"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {/* Attachment */}
        {/* <Button className="px-1 font-semibold tracking-wide" type="primary">
          <Attachment className="w-5 h-5 text-white" />
        </Button> */}
        <Popover content={EmojiPicker} title="" trigger="click" rootClassName="chatboxControls">
          <Button className="px-1 font-semibold tracking-wide" type="primary">
            <EmojiBlinkLeft className="w-5 h-5 text-white" />
          </Button>
        </Popover>
        {/* GIF Search */}
        {/* <Button className="px-3 font-semibold tracking-wide bg-slate-700" type="primary">
          GIF
        </Button> */}
        <Button
          className="flex items-center justify-center font-semibold tracking-wide bg-blue-600 rounded-full hover:bg-blue-700"
          type="primary"
          onClick={() => sendMessage()}
        >
          <SendDiagonal className="w-4 h-4 text-white" />
        </Button>
      </div>
    </div>
  );
};

export default Chatbox;
