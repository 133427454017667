import { useState, useEffect } from "react";
import Button from "../../../components/button";
import { Input, Modal, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { FilePenLine, FilterX, Loader2, PiggyBank } from "lucide-react";
import { CheckWidgetInstall, GetWidget, SetWidgetDomain } from "../../../actions/api";
import toast from "react-hot-toast";
import { formatCurrency } from "../../../components/utils";
import validator from "validator";
import { BellNotification, ChatBubbleEmpty, Code, Copy, CreditCard, MultiWindow, MultiplePages, SquareCursor } from "iconoir-react";
import SearchIllustration from "../../../components/searchIllustration";
import SuccessIllustration from "../../../components/successIllustration";

const OpenWidget = () => {
  const [loading, setLoading] = useState(true);
  const [widget, setWidget] = useState(null);
  const [installStep, setInstallStep] = useState(0);
  const [installModal, setInstallModal] = useState(false);
  const [domain, setDomain] = useState("");
  const [installSuccess, setInstallSuccess] = useState(false);
  const [settingsPage, setSettingsPage] = useState("chat");

  const navigate = useNavigate();
  const { widgetId } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetWidget(widgetId)
        .then((res) => {
          setWidget(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error retrieving widget data");
          navigate("/account/widgets");
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const closeInstallModal = () => {
    setInstallModal(false);
    setInstallStep(0);
  };

  const submitWidgetDomain = () => {
    if (domain === "") {
      toast.error("Please enter a valid domain");
      return;
    } else if (!validator.isURL(domain)) {
      toast.error("Please enter a valid domain");
      return;
    } else {
      SetWidgetDomain(widgetId, { domain })
        .then((res) => {
          toast.success("Widget domain set successfully");
          setInstallStep(1);
          GetWidget(widgetId)
            .then((res) => {
              setWidget(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error retrieving widget data");
              navigate("/account/widgets");
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error setting widget domain");
          setLoading(false);
        });
    }
  };

  const getScriptTag = () => {
    return `<script type="module" src="https://storage.googleapis.com/hyperook-widgets/app.bundle.js?client-id=${widgetId}" id="hyperook"></script>`;
  };

  const proceedToCheck = () => {
    setLoading(true);
    setInstallStep(2);
    CheckWidgetInstall(widgetId)
      .then((res) => {
        if (res.data.isWidgetPresent) {
          setInstallSuccess(true);
          toast.success("Widget installed successfully");
        }
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        setTimeout(() => setLoading(false), 700);
        toast.error(err.response.data ? err.response.data.message : "Error checking widget install");
      });
  };

  const tryAgainOrClose = () => {
    if (installSuccess) {
      closeInstallModal();
      setInstallStep(0);
      setLoading(true);
      GetWidget(widgetId)
        .then((res) => {
          setWidget(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error retrieving widget data");
          navigate("/account/widgets");
        });
    } else {
      CheckWidgetInstall(widgetId)
        .then((res) => {
          if (res.data.isWidgetPresent) {
            setInstallSuccess(true);
            toast.success("Widget installed successfully");
          }
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error checking widget install");
        });
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let settingsNames = {
    chat: "Text-Us Chatbox Settings",
    cta: "CTA Card Settings",
    popup: "Popup Dialog Settings",
    install: "Widget Installation",
    notifications: "Notifications Settings",
    tracking: "Tracking Settings",
    submissions: "Submissions",
  };

  const renderSettingsStep = () => {
    if (settingsPage === "chat") {
      return (
        <div className="h-full px-6 py-8 bg-black sm:px-10 rounded-xl">
          <h2 className="mb-6 text-3xl font-semibold text-gray-100">{settingsNames[settingsPage]}</h2>
          <div className="flex items-center justify-between mb-8 -mx-4">
            <div className="max-w-md px-4">
              <h4 className="font-bold text-gray-50">Chatbox Widget Enabled</h4>
              <p className="text-xs font-medium leading-normal text-gray-300">
                Show a chatbox in the bottom right corner of your website, allowing visitors to submit their phone number to request a texting conversation.
              </p>
            </div>
            <div className="px-4">
              <Switch defaultChecked={widget.chatEnabled} />
            </div>
          </div>
          <div className="flex flex-col items-start justify-center gap-2 mb-8 -mx-4">
            <div className="max-w-md px-4">
              <h4 className="font-bold text-gray-50">Widget Title</h4>
            </div>
            <div className="w-full px-4">
              <Input placeholder="Input Label" defaultValue={widget.chatSettings.title} className="w-full" />
            </div>
          </div>
          <div className="flex flex-col items-start justify-center gap-2 mb-8 -mx-4">
            <div className="max-w-md px-4">
              <h4 className="font-bold text-gray-50">Submit Button Text</h4>
            </div>
            <div className="w-full px-4">
              <Input placeholder="Input Label" defaultValue={widget.chatSettings.buttonText} className="w-full" />
            </div>
          </div>
          <div className="flex items-center justify-end px-4 mb-8 -mx-4">
            <Button type="secondary" label="Save Changes" />
          </div>
        </div>
      );
    } else if (settingsPage === "cta") {
    } else if (settingsPage === "popup") {
    } else if (settingsPage === "install") {
    } else if (settingsPage === "notifications") {
    } else if (settingsPage === "tracking") {
    } else if (settingsPage === "submissions") {
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full max-h-screen gap-4 px-4 pt-10 sm:px-6 md:px-8 lg:pl-72">
      <div className="w-full pb-5 border-b border-gray-200">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start gap-2">
            <h3 className="text-xl font-bold leading-6 text-gray-900">Widget{widget && ` - ${widget.title}`}</h3>
            {widget && (
              <>
                {!widget.active ? (
                  <div className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-red-500 py-1.5 px-3.5 align-baseline font-sans text-xs font-semibold uppercase leading-none text-white">
                    <div className="mt-px">INACTIVE</div>
                  </div>
                ) : widget.installed ? (
                  <div className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-green-500 py-1.5 px-3.5 align-baseline font-sans text-xs font-semibold uppercase leading-none text-white">
                    <div className="mt-px">ACTIVE & INSTALLED</div>
                  </div>
                ) : (
                  <div className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-red-500 py-1.5 px-3.5 align-baseline font-sans text-xs font-semibold uppercase leading-none text-white">
                    <div className="mt-px">NOT INSTALLED</div>
                  </div>
                )}
              </>
            )}
          </div>
          <Button type="secondary" label="Edit" onClick={() => toast("Soon...")} />
        </div>
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center flex-grow gap-4 overflow-y-auto content">
          <Loader2 className="w-10 h-10 text-gray-400 animate-spin" />
          <p className="text-sm font-medium text-gray-500 uppercase animate-pulse">Retrieving Widget Data...</p>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-start flex-grow gap-5 py-5 overflow-y-auto content">
          <section className="w-full" id="stats">
            <div className="container flex items-center justify-start px-10 mx-auto mb-5">
              <h2 className="text-2xl font-semibold">Statistics</h2>
            </div>
            <div className="container flex items-center justify-center px-4 mx-auto">
              <div className="flex flex-wrap items-center justify-center w-full -mx-4 xl:-mx-7">
                <div className="w-full px-4 mb-8 md:w-1/2 xl:w-1/3 xl:mb-0">
                  <div className="px-8 py-8 bg-white shadow-lg rounded-3xl">
                    <FilePenLine className="mb-6" size={24} color="#3B82F6" />
                    <span className="text-3xl font-semibold">{widget.stats.totalSubmissions}</span>
                    <h3 className="mt-4 text-sm font-medium text-darkBlueGray-300">Started Forms</h3>
                  </div>
                </div>
                <div className="w-full px-4 mb-8 md:w-1/2 xl:w-1/3 xl:mb-0">
                  <div className="px-8 py-8 bg-white shadow-lg rounded-3xl">
                    <FilterX className="mb-6" size={24} color="#3B82F6" />
                    <span className="text-3xl font-semibold">
                      {(((widget.stats.totalSubmissions - widget.stats.completedSubmissions) / widget.stats.totalSubmissions) * 100).toFixed(2)}%
                    </span>
                    <h3 className="mt-4 text-sm font-medium text-darkBlueGray-300">Abandoned Forms</h3>
                  </div>
                </div>
                <div className="w-full px-4 mb-8 md:w-1/2 xl:w-1/3 xl:mb-0">
                  <div className="px-8 py-8 bg-white shadow-lg rounded-3xl">
                    <PiggyBank className="mb-6" size={24} color="#3B82F6" />
                    <span className="text-3xl font-semibold">{formatCurrency(widget.stats.totalRevenue)}</span>
                    <h3 className="mt-4 text-sm font-medium text-darkBlueGray-300">Revenue Collected</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {(!widget.installed || widget.domain === "") && (
            <section className="w-full" id="banner">
              <div className="container px-8 mx-auto">
                <div className="relative px-8 py-12 overflow-hidden lg:py-18 md:px-18 rounded-2xl bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-slate-800 via-slate-800 to-slate-950">
                  <div className="flex items-center justify-between w-full max-w-5xl mx-auto">
                    <div className="relative flex flex-col items-start justify-center max-w-sm gap-2">
                      <h3 className="text-3xl font-bold text-white">Finish Widget Installation</h3>
                      <p className="text-sm font-medium text-blue-200">Complete your widget setup by installing it on your website</p>
                    </div>
                    <Button
                      type="primary"
                      label="Install Widget"
                      onClick={() => setInstallModal(true)}
                      className="py-2.5 px-8 hover:bg-white hover:text-gray-950 bg-sky-500/8 text-white uppercase font-semibold focus:ring-0 focus:outline-none focus:ring-transparent"
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="w-full" id="options">
            <div className="container px-8 mx-auto">
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3 mb-12 lg:w-1/3 lg:mb-0">
                  <div className="mb-4">
                    <div className="overflow-hidden bg-black rounded-xl">
                      <h3 className="px-8 pt-6 mb-4 text-sm font-semibold text-white uppercase">Widget Addons</h3>
                      <div
                        className={classNames(
                          settingsPage === "chat" ? "bg-blue-500 bg-opacity-20 hover:bg-opacity-30" : "hover:bg-blue-500 hover:bg-opacity-20",
                          "flex items-center px-4 py-6 transition-all duration-200 cursor-pointer sm:px-8 group"
                        )}
                        onClick={() => setSettingsPage("chat")}
                      >
                        <div
                          className={classNames(
                            settingsPage === "chat" ? "bg-blue-500 rounded-full text-blue-50" : "text-black bg-white group-hover:text-blue-500",
                            "flex items-center self-start justify-center flex-shrink-0 w-12 h-12 mr-4 transition-all duration-200 rounded-full"
                          )}
                        >
                          <ChatBubbleEmpty className="w-6 h-6" />
                        </div>
                        <div>
                          <h4 className="mb-1 text-sm font-semibold text-gray-100">Text-Us Chatbox</h4>
                          <p className="text-xs font-medium text-gray-400 transition duration-200 group-hover:text-gray-200">SMS Texting Widget Settings</p>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          settingsPage === "cta" ? "bg-blue-500 bg-opacity-20 hover:bg-opacity-30" : "hover:bg-blue-500 hover:bg-opacity-20",
                          "flex items-center px-4 py-6 transition-all duration-200 cursor-pointer sm:px-8 group"
                        )}
                        onClick={() => setSettingsPage("cta")}
                      >
                        <div
                          className={classNames(
                            settingsPage === "cta" ? "bg-blue-500 rounded-full text-blue-50" : "text-black bg-white group-hover:text-blue-500",
                            "flex items-center self-start justify-center flex-shrink-0 w-12 h-12 mr-4 transition-all duration-200 rounded-full"
                          )}
                        >
                          <CreditCard className="w-6 h-6" />
                        </div>
                        <div>
                          <h4 className="mb-1 text-sm font-semibold text-gray-100">CTA Card</h4>
                          <p className="text-xs font-medium text-gray-400 transition duration-200 group-hover:text-gray-200">CTA Card Widget Settings</p>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          settingsPage === "popup" ? "bg-blue-500 bg-opacity-20 hover:bg-opacity-30" : "hover:bg-blue-500 hover:bg-opacity-20",
                          "flex items-center px-4 py-6 transition-all duration-200 cursor-pointer sm:px-8 group"
                        )}
                        onClick={() => setSettingsPage("popup")}
                      >
                        <div
                          className={classNames(
                            settingsPage === "popup" ? "bg-blue-500 rounded-full text-blue-50" : "text-black bg-white group-hover:text-blue-500",
                            "flex items-center self-start justify-center flex-shrink-0 w-12 h-12 mr-4 transition-all duration-200 rounded-full"
                          )}
                        >
                          <MultiWindow className="w-6 h-6" />
                        </div>
                        <div>
                          <h4 className="mb-1 text-sm font-semibold text-gray-100">Popup Dialog</h4>
                          <p className="text-xs font-medium text-gray-200">Popup Dialog Settings</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="overflow-hidden bg-black rounded-xl">
                      <h3 className="px-8 pt-6 mb-4 text-sm font-semibold text-white uppercase">Widget Settings</h3>
                      <div
                        className={classNames(
                          settingsPage === "install" ? "bg-blue-500 bg-opacity-20 hover:bg-opacity-30" : "hover:bg-blue-500 hover:bg-opacity-20",
                          "flex items-center px-4 py-6 transition-all duration-200 cursor-pointer sm:px-8 group"
                        )}
                        onClick={() => setSettingsPage("install")}
                      >
                        <div
                          className={classNames(
                            settingsPage === "install" ? "bg-blue-500 rounded-full text-blue-50" : "text-black bg-white group-hover:text-blue-500",
                            "flex items-center self-start justify-center flex-shrink-0 w-12 h-12 mr-4 transition-all duration-200 rounded-full"
                          )}
                        >
                          <Code className="w-6 h-6" />
                        </div>
                        <div>
                          <h4 className="mb-1 text-sm font-semibold text-gray-100">Widget Installation</h4>
                          <p className="text-xs font-medium text-gray-400 transition duration-200 group-hover:text-gray-200">
                            Widget domain and installation script
                          </p>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          settingsPage === "notifications" ? "bg-blue-500 bg-opacity-20 hover:bg-opacity-30" : "hover:bg-blue-500 hover:bg-opacity-20",
                          "flex items-center px-4 py-6 transition-all duration-200 cursor-pointer sm:px-8 group"
                        )}
                        onClick={() => setSettingsPage("notifications")}
                      >
                        <div
                          className={classNames(
                            settingsPage === "notifications" ? "bg-blue-500 rounded-full text-blue-50" : "text-black bg-white group-hover:text-blue-500",
                            "flex items-center self-start justify-center flex-shrink-0 w-12 h-12 mr-4 transition-all duration-200 rounded-full"
                          )}
                        >
                          <BellNotification className="w-6 h-6" />
                        </div>
                        <div>
                          <h4 className="mb-1 text-sm font-semibold text-gray-100">Notifications</h4>
                          <p className="text-xs font-medium text-gray-400 transition duration-200 group-hover:text-gray-200">
                            Notifications and alerts settings
                          </p>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          settingsPage === "tracking" ? "bg-blue-500 bg-opacity-20 hover:bg-opacity-30" : "hover:bg-blue-500 hover:bg-opacity-20",
                          "flex items-center px-4 py-6 transition-all duration-200 cursor-pointer sm:px-8 group"
                        )}
                        onClick={() => setSettingsPage("tracking")}
                      >
                        <div
                          className={classNames(
                            settingsPage === "tracking" ? "bg-blue-500 rounded-full text-blue-50" : "text-black bg-white group-hover:text-blue-500",
                            "flex items-center self-start justify-center flex-shrink-0 w-12 h-12 mr-4 transition-all duration-200 rounded-full"
                          )}
                        >
                          <SquareCursor className="w-6 h-6" />
                        </div>
                        <div>
                          <h4 className="mb-1 text-sm font-semibold text-gray-100">Tracking</h4>
                          <p className="text-xs font-medium text-gray-400 transition duration-200 group-hover:text-gray-200">
                            Analytics and event tracking settings
                          </p>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          settingsPage === "submissions" ? "bg-blue-500 bg-opacity-20 hover:bg-opacity-30" : "hover:bg-blue-500 hover:bg-opacity-20",
                          "flex items-center px-4 py-6 transition-all duration-200 cursor-pointer sm:px-8 group"
                        )}
                        onClick={() => setSettingsPage("submissions")}
                      >
                        <div
                          className={classNames(
                            settingsPage === "submissions" ? "bg-blue-500 rounded-full text-blue-50" : "text-black bg-white group-hover:text-blue-500",
                            "flex items-center self-start justify-center flex-shrink-0 w-12 h-12 mr-4 transition-all duration-200 rounded-full"
                          )}
                        >
                          <MultiplePages className="w-6 h-6" />
                        </div>
                        <div>
                          <h4 className="mb-1 text-sm font-semibold text-gray-100">Submissions</h4>
                          <p className="text-xs font-medium text-gray-400 transition duration-200 group-hover:text-gray-200">Submissions History</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full px-3 lg:w-2/3 widgetSettings">{renderSettingsStep()}</div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal
        open={installModal}
        onClose={() => closeInstallModal()}
        onCancel={() => closeInstallModal()}
        centered={true}
        footer={null}
        width={800}
        title="Install your widget"
      >
        {installStep === 0 ? (
          <>
            <p className="text-base">Let's start off by configuring your domain and letting us know where will you be placing the widget.</p>
            <Input type="url" placeholder="https://hyperook.com" className="mt-4" onChange={(e) => setDomain(e.target.value)} />
            <div className="flex items-center justify-end w-full mt-4">
              <Button label="Configure Domain" onClick={() => submitWidgetDomain()} disabled={loading} />
            </div>
          </>
        ) : installStep === 1 ? (
          <div className="flex flex-col items-start justify-start w-full gap-4 py-3">
            <p className="px-2 text-base">
              Great! Now let's add the widget code onto {domain}! Paste the following code right before{" "}
              <span className="px-2 py-1 font-mono text-sm font-semibold bg-gray-200/70">{`</body>`}</span> in your website's HTML.
            </p>
            <div className="flex items-center justify-between w-full px-4 py-3 font-mono text-sm text-gray-800 bg-white border border-gray-300 rounded-md">
              <div className="flex gap-1">
                <span>{getScriptTag()}</span>
              </div>
              <Copy
                className="flex w-12 h-12 text-gray-800 duration-200 cursor-pointer hover:text-gray-400"
                onClick={() => navigator.clipboard.writeText(getScriptTag())}
              />
            </div>
            <div className="flex items-center justify-end w-full mt-2">
              <Button label="I've added the code" onClick={() => proceedToCheck()} />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-start justify-start w-full gap-4 py-3">
            <p className="px-2 text-base">
              We're confirming that your widget is installed correctly. If you lost the code for the widget and need to reinstall it, you can click{" "}
              <span
                className="font-semibold text-gray-500 underline transition-all duration-150 cursor-pointer hover:text-black underline-offset-2"
                onClick={() => navigator.clipboard.writeText(getScriptTag())}
              >
                here
              </span>{" "}
              to copy it again.
            </p>
            <div className="flex items-center justify-center w-full px-4 py-3">
              {installSuccess ? <SuccessIllustration className="w-auto h-60" /> : <SearchIllustration className="w-auto h-60 animate-pulse" />}
            </div>
            <div className="flex items-center justify-end w-full mt-2">
              <Button label={installSuccess ? "Finish" : "Check Again"} onClick={() => tryAgainOrClose()} />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OpenWidget;
