import axiosInstance from "./axios";

export const GetUser = () => {
  return axiosInstance.get("/auth");
};

export const SignUp = (data) => {
  return axiosInstance.post("/auth/register", data);
};

export const SignIn = (data) => {
  return axiosInstance.post("/auth/login", data);
};

export const ForgotPasswordAPI = (data) => {
  return axiosInstance.post("/auth/forgotPassword", data);
};

export const ResetPasswordAPI = (data) => {
  return axiosInstance.post("/auth/resetPassword", data);
};

export const GetDashboard = () => {
  return axiosInstance.get("/auth/dashboard");
};
