import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { AddLeadNote, GetLead } from "../../actions/leads";
import toast from "react-hot-toast";
import io from "socket.io-client";
import Chatbox from "../../components/Chatbox";
import { Button, Collapse, Input } from "antd";
import dayjs from "dayjs";
import { Calendar, Filter, Magnet, Mail, Phone, User, UserCart } from "iconoir-react";
import { formatCurrency } from "../../components/utils";
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const MessagesLeadId = () => {
  const [loading, setLoading] = useState(true);
  const [lead, setLead] = useState(null);
  const [members, setMembers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [submissions, setSubmissions] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const socketRef = useRef(null);

  const navigate = useNavigate();
  const { leadId } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (leadId && leadId.length > 0) {
        GetLead(leadId)
          .then((res) => {
            setLead(res.data.lead);
            setMembers(res.data.members);
            setSubmissions(res.data.submissions);
            setWidgets(res.data.widgets);
            socketRef.current = io(process.env.REACT_APP_API_HOST, {
              auth: {
                token: localStorage.getItem("auth-token"),
              },
              extraHeaders: {
                leadId: leadId,
              },
            });
            socketRef.current.on("connected_to_messaging_system", (data) => {
              setMessages(data);
              setTimeout(() => setLoading(false), 700);
            });
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error retrieving message history for this lead.");
          });
      } else {
        toast.error("Message history with this lead was not found.");
        navigate("/messages");
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderNote = (note) => {
    return (
      <div className="flex flex-col items-center justify-center w-full gap-1 px-3 py-2 rounded-md bg-gray-200/80">
        <p className="w-full text-black">{note.note}</p>
        <div className="flex items-center justify-between w-full">
          <p className="text-xs text-gray-500">{dayjs(note.timestamp).format("MM/DD/YY h:mm A")}</p>
          <p className="text-xs text-gray-500">{note.addedByName}</p>
        </div>
      </div>
    );
  };

  const submitNote = () => {
    setLoading(true);
    AddLeadNote(leadId, { note: newNote })
      .then((res) => {
        setNewNote("");
        GetLead(leadId)
          .then((res) => {
            setLead(res.data.lead);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "Error retrieving message history for this lead.");
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Error adding note.");
        setLoading(false);
      });
  };

  const renderNotes = () => {
    return (
      <div className="flex flex-col items-center justify-between w-full gap-5">
        <div className="flex flex-col justify-start items-center gap-2 w-full max-h-[300px]">
          {!loading && lead.notes && lead.notes.length > 0 ? lead.notes.map((note) => renderNote(note)) : <p className="text-gray-400">No notes were added</p>}
        </div>
        <div className="flex flex-col items-end justify-start w-full gap-1">
          <Input.TextArea
            className="w-full"
            autoSize={{ minRows: 3, maxRows: 3 }}
            placeholder="Add your note here..."
            onChange={(e) => setNewNote(e.target.value)}
            value={newNote}
          />
          <Button type="default" onClick={() => submitNote()}>
            Add Note
          </Button>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="grid w-full grid-cols-2 gap-3">
        <div className="flex items-center justify-center w-full h-24 transition-all duration-200 border border-gray-200 rounded-lg cursor-pointer hover:border-gray-400 hover:shadow-md">
          <p>Collect Payment</p>
        </div>
        <div className="flex items-center justify-center w-full h-24 transition-all duration-200 border border-gray-200 rounded-lg cursor-pointer hover:border-gray-400 hover:shadow-md">
          <p>Request Review</p>
        </div>
      </div>
    );
  };

  const renderLeadInfo = () => {
    let ltv = 0;
    for (let i = 0; i < submissions.length; i++) {
      if (submissions[i].payment && submissions[i].payment.amount && submissions[i].payment.amount > 0) {
        ltv += submissions[i].payment.amount;
      }
    }
    return (
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <div className="flex items-center justify-start w-full gap-2">
          <User className="w-6 h-6 text-gray-500" strokeWidth={2} />
          <p className="font-medium text-gray-700">{lead.name}</p>
        </div>
        <div
          className={`flex items-center justify-start w-full gap-2 ${lead.mail && lead.mail.length > 0 && "cursor-pointer"}`}
          onClick={() => lead.mail && lead.mail.length > 0 && window.open(`mailto:${lead.mail}`)}
        >
          <Mail className="w-6 h-6 text-gray-500" strokeWidth={2} />
          <p className="font-medium text-gray-700">{lead.mail && lead.mail.length > 0 ? lead.mail : "Not Provided"}</p>
        </div>
        <div
          className={`flex items-center justify-start w-full gap-2 ${lead.phone && lead.phone.length > 0 && "cursor-pointer"}`}
          onClick={() => lead.phone && lead.phone.length > 0 && window.open(`tel:${lead.phone}`)}
        >
          <Phone className="w-6 h-6 text-gray-500" strokeWidth={2} />
          <p className="font-medium text-gray-700">
            {lead.phone && lead.phone.length > 0
              ? "+1 (" + lead.phone.slice(0, 3) + ") " + lead.phone.slice(3, 6) + "-" + lead.phone.slice(6, 10)
              : "Not Provided"}
          </p>
        </div>
        <div className="flex items-center justify-start w-full gap-2">
          <Magnet className="w-6 h-6 text-gray-500" strokeWidth={2} />
          <p className="font-medium text-gray-700">Source: {lead.source && lead.source.length > 0 ? lead.source : "Unknown"}</p>
        </div>
        <div className="flex items-center justify-start w-full gap-2">
          <Calendar className="w-6 h-6 text-gray-500" strokeWidth={2} />
          <p className="font-medium text-gray-700">Created on {dayjs(lead.leadCreated).format("MMMM Do, YYYY")}</p>
        </div>
        <div className="flex items-center justify-start w-full gap-2">
          <Filter className="w-6 h-6 text-gray-500" strokeWidth={2} />
          <p className="font-medium text-gray-700">Lead Status: {lead.leadStatus}</p>
        </div>
        <div className="flex items-center justify-start w-full gap-2">
          <UserCart className="w-6 h-6 text-gray-500" strokeWidth={2} />
          <p className="font-medium text-gray-700">
            LTV: <span className={`${ltv > 0 && "text-green-500"}`}>{formatCurrency(ltv)}</span>
          </p>
        </div>
      </div>
    );
  };

  const renderPaymentHistory = () => {
    let payments = [];
    for (let i = 0; i < submissions.length; i++) {
      if (submissions[i].payment && submissions[i].payment.amount && submissions[i].payment.amount > 0) {
        payments.push(submissions[i].payment);
      }
    }
    return (
      <div className="flex flex-col items-start justify-start w-full gap-2">
        {payments.length > 0 ? (
          payments.map((payment) => (
            <div className="flex items-center justify-between w-full px-4 py-3 transition-all duration-200 border border-gray-300 rounded-md cursor-pointer hover:border-gray-600 hover:shadow-md">
              <p className="text-gray-500">{dayjs(payment.capturedOn).format("MM/DD/YYYY")}</p>
              <p className="font-medium text-gray-950">{formatCurrency(payment.amount)}</p>
            </div>
          ))
        ) : (
          <p className="text-gray-400">No payment history</p>
        )}
      </div>
    );
  };

  const renderSubmissions = () => {
    function completeChip() {
      return (
        <div className="relative inline-block px-3 py-1 font-sans text-xs font-semibold leading-3 text-white uppercase bg-green-500 rounded-lg select-none center whitespace-nowrap">
          Completed
        </div>
      );
    }
    function abandonedChip() {
      return (
        <div className="relative inline-block px-3 py-1 font-sans text-xs font-semibold leading-3 text-white uppercase bg-red-500 rounded-lg select-none center whitespace-nowrap">
          Abandoned
        </div>
      );
    }
    function renderSubmission(submission) {
      let widget = widgets.find((w) => w.widgetId === submission.widgetId);
      let widgetName = widget ? "Source: " + widget.title : "Unknown Source";
      return (
        <div
          className="flex flex-col items-center justify-center w-full gap-2 px-4 py-3 transition-all duration-200 border border-gray-300 rounded-md cursor-pointer hover:border-gray-600 hover:shadow-md"
          onClick={() => navigate(`/submissions/${submission.submissionId}`)}
        >
          <div className="flex items-center justify-between w-full">
            <p className="text-gray-500">{dayjs(submission.startedOn).format("MM/DD/YYYY h:mm A")}</p>
            {submission.completed ? completeChip() : abandonedChip()}
          </div>
          <p className="w-full font-normal text-gray-700">{widgetName}</p>
        </div>
      );
    }
    return (
      <div className="flex flex-col items-start justify-start w-full gap-2">
        {submissions.length > 0 ? submissions.map((submission) => renderSubmission(submission)) : <p className="text-gray-400">No submission history</p>}
      </div>
    );
  };

  return !loading ? (
    <div className="flex flex-col items-center justify-start w-full h-full max-h-screen pl-4 sm:pl-6 md:pl-8 lg:pl-64">
      <div className="grid w-full h-full grid-cols-12">
        <Chatbox leadData={lead} membersData={members} socket={socketRef.current} initialMessages={messages} />
        <div className="flex flex-col items-center justify-start h-full col-span-3 gap-5 px-4 py-3 border-l border-gray-200 bg-gray-50">
          <p className="my-2 font-semibold text-black">Actions</p>
          <Collapse
            className="w-full"
            accordion
            destroyInactivePanel
            items={[
              {
                key: "leadInfo",
                label: "Lead Info",
                children: renderLeadInfo(),
              },
              {
                key: "notes",
                label: "Notes",
                children: renderNotes(),
              },
              {
                key: "actions",
                label: "Actions",
                children: renderActions(),
              },
              {
                key: "paymentHistory",
                label: "Payment History",
                children: renderPaymentHistory(),
              },
              {
                key: "appointment",
                label: "Appointment",
                children: <p>No appointment to show</p>,
              },
              {
                key: "submissions",
                label: "Submissions",
                children: renderSubmissions(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full h-full px-4 pt-10 sm:px-6 md:px-8 lg:pl-72">
      <p className="text-sm font-light tracking-widest uppercase font-body animate-pulse">Loading</p>
    </div>
  );
};

export default MessagesLeadId;
