export const stepFieldTypes = {
  address: "Address Capture and Verification",
  contact: "Contact Information",
  boolean: "Yes/No Question",
  "single-select": "Single Choice Question",
  "multi-select": "Multi Choice Question",
  custom: "Custom Step",
  payment: "Payment Capture",
  info: "Info Step",
  addon: "Add-On / Upsell",
};

export const BaseStepsAndFields = [
  {
    isFirstStep: true,
    isLastStep: false,
    title: "Get Started",
    description: "Tell us about yourself",
    buttonText: "Next",
    stepType: "contact",
    fields: [
      {
        type: "text",
        label: "Name",
        name: "name",
        placeholder: "Name",
        required: true,
      },
      {
        type: "text",
        label: "Phone",
        name: "phone",
        placeholder: "(555) 555-5555",
        required: true,
      },
      {
        type: "text",
        label: "Email",
        name: "email",
        placeholder: "Email",
        required: true,
      },
    ],
    config: {
      phone: true,
      email: true,
    },
  },
  {
    isFirstStep: false,
    isLastStep: true,
    title: "Thank you!",
    description: "We received your information and will be in touch soon.",
    buttonText: "Finish",
    stepType: "info",
    fields: [],
    config: {},
  },
];
