import { useState, useRef, useCallback } from "react";
import ReactFlow, { useNodesState, useEdgesState, addEdge, useReactFlow, ReactFlowProvider, Panel } from "reactflow";
import FlowNode from "../../components/FlowNode";
import TriggerNode from "../../components/TriggerNode";
import "reactflow/dist/style.css";
import { Button, Modal, Select } from "antd";

const initialNodes = [
  {
    id: "0",
    type: "trigger",
    data: { label: "Trigger", type: "", value: "" },
    position: { x: 0, y: 50 },
  },
  {
    id: "1",
    type: "custom",
    data: { label: "Action 1", type: "", value: "" },
    position: { x: 0, y: 120 },
  },
];

const nodeTypes = { custom: FlowNode, trigger: TriggerNode };

const initialEdges = [
  {
    type: "default",
    source: "0",
    target: "1",
    id: "1",
  },
];

let id = 2;
const getId = () => `${id++}`;

const FlowDiagram = () => {
  const [selectedNode, setSelectedNode] = useState(null);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { screenToFlowPosition } = useReactFlow();

  // const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: { label: `Action ${id}` },
          origin: [0.5, 0.0],
          type: "custom",
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => eds.concat({ id, source: connectingNodeId.current, target: id }));
      }
    },
    [screenToFlowPosition]
  );

  const clicked = (event, node) => {
    setSelectedNode(node);
  };

  return (
    <div className="flex-grow h-full bg-slate-800" ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        zoomOnScroll={false}
        fitView
        fitViewOptions={{ padding: 2 }}
        nodeOrigin={[0.5, 0]}
        onNodeClick={clicked}
        maxZoom={1.5}
        minZoom={1.5}
      >
        <Panel position="bottom-center">
          <p className="text-sm text-gray-400">Click on one of the items in order to bring up their options</p>
        </Panel>
      </ReactFlow>
      <Modal title="Options" open={selectedNode !== null} onCancel={() => setSelectedNode(null)} footer={null} centered destroyOnClose>
        <div className="flex flex-col justify-start items-start gap-2">
          <p>Type</p>
          <Select
            className="w-full"
            options={[
              { label: "Trigger", value: "trigger" },
              { label: "Action", value: "custom" },
            ]}
            defaultValue={selectedNode?.type}
            disabled
            placeholder="Select an option"
          />
          <p>Value</p>
          <Select
            className="w-full"
            options={[
              { label: "Send a text message", value: "sms" },
              { label: "Wait", value: "wait" },
              { label: "Request a review", value: "review" },
            ]}
            placeholder="Select an option"
          />
          <div className="w-full flex flex-row justify-end items-center gap-2 mt-4">
            <Button className="border border-blue-800">Cancel</Button>
            <Button className="bg-blue-800">Update</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const NewAutomation = () => {
  return (
    <div className="w-full pl-4 sm:pl-6 md:pl-8 lg:pl-64 h-full flex flex-col justify-start items-center max-h-screen bg-gray-950">
      <div className="w-full flex flex-row justify-between items-center bg-gray-950 p-4">
        <p className="text-slate-400">New Automation</p>
        <button className="py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-950 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          Save Changes
        </button>
      </div>
      <div className="w-full h-full p-5">
        <ReactFlowProvider>
          <FlowDiagram />
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export default NewAutomation;
