import axiosInstance from "./axios";

export const CreateNewWidget = (data) => {
  return axiosInstance.post("/api/widget", data);
};

export const GetWidget = (id) => {
  return axiosInstance.get(`/api/widget/${id}`);
};

export const GetAllWidgets = () => {
  return axiosInstance.get("/api/widget");
};

export const SetWidgetDomain = (id, data) => {
  return axiosInstance.post(`/api/widget/${id}/domain`, data);
};

export const CheckWidgetInstall = (id) => {
  return axiosInstance.get(`/api/widget/${id}/check`);
};
