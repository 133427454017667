import { Menu } from "iconoir-react";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";

function TriggerNode({ data }) {
  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-slate-700 border border-gray-800">
      <div className="flex">
        <p className="text-sm font-medium">{data.label}</p>
      </div>

      <Handle type="source" position={Position.Bottom} className="w-16 !bg-pink-500" />
    </div>
  );
}

export default memo(TriggerNode);
