import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, createBrowserRouter } from "react-router-dom";
import { AuthProvider, AuthConsumer } from "./context";
import { ConfigProvider } from "antd";

let antThemeSettings = {
  token: {
    colorPrimary: "#1a56db",
    colorInfo: "#1a56db",
    colorLink: "#1a56db",
    borderRadius: 5,
    colorError: "#c81e1e",
    colorSuccess: "#49a078",
    wireframe: false,
    colorWarning: "#fdca40",
    colorBgElevated: "#f9fafb",
    colorBgContainer: "#f9fafb",
    colorTextBase: "#1f2937",
  },
  components: {
    Button: {
      algorithm: true,
      controlOutline: "rgba(240, 248, 255, 0)",
      colorErrorOutline: "rgba(255, 240, 240, 0)",
      defaultColor: "rgb(56, 56, 56)",
      colorPrimary: "rgb(26, 86, 219)",
      colorTextLightSolid: "rgb(255, 255, 255)",
      colorText: "rgb(54, 54, 54)",
      defaultGhostColor: "rgb(158, 158, 158)",
      defaultGhostBorderColor: "rgb(215, 214, 214)",
      primaryColor: "rgb(255, 255, 255)",
      defaultBg: "rgb(249, 250, 251)",
    },
    Input: {
      colorBgContainer: "rgb(23, 37, 84, 0)",
      activeBg: "rgb(23, 37, 84, 0)",
      hoverBg: "rgb(23, 37, 84, 0)",
      hoverBorderColor: "rgb(26, 86, 219)",
      activeBorderColor: "rgba(26, 86, 219, 0.7)",
      algorithm: true,
    },
    Collapse: {
      contentBg: "rgb(249, 250, 251)",
      colorBorder: "rgb(229, 231, 235)",
      colorTextHeading: "rgb(34, 37, 42)",
    },
    Select: {
      colorBorder: "rgb(209, 213, 219)",
      colorTextPlaceholder: "rgb(167, 170, 175)",
      clearBg: "rgba(255, 255, 255, 0)",
      colorBgContainer: "rgba(255, 255, 255, 0)",
      controlHeight: 38,
    },
    Alert: {
      colorSuccessBg: "rgba(220, 252, 231, 0.5)",
      colorSuccess: "rgb(63, 126, 109)",
      colorSuccessBorder: "rgba(63, 126, 109, 0)",
      colorWarningBorder: "rgba(255, 244, 186, 0)",
      colorWarningBg: "rgba(254, 249, 195, 0.5)",
      colorWarning: "rgb(250, 204, 21)",
      colorInfoBg: "rgba(219, 234, 254, 0.5)",
      colorInfoBorder: "rgba(153, 196, 255, 0)",
      colorErrorBg: "rgba(254, 226, 226, 0.5)",
      colorErrorBorder: "rgba(237, 158, 149, 0)",
      colorError: "rgb(239, 68, 68)",
      colorText: "rgb(75, 85, 99)",
      colorTextHeading: "rgb(43, 48, 54)",
    },
    Divider: {
      colorSplit: "rgba(212, 212, 216, 0.5)",
      colorText: "rgb(75, 85, 99)",
      colorTextHeading: "rgb(43, 48, 54)",
    },
    Modal: {
      colorBgMask: "rgba(0, 0, 0, 0.6)",
      colorText: "rgb(75, 85, 99)",
      titleColor: "rgb(43, 48, 54)",
    },
    Slider: {
      handleColor: "rgb(22, 119, 255)",
      railBg: "rgba(0, 0, 0, 0.15)",
      trackBg: "rgb(22, 119, 255)",
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("hyperook"));
root.render(
  <StrictMode>
    <AuthProvider>
      <AuthConsumer>
        {(authState) => (
          <BrowserRouter>
            <ConfigProvider theme={antThemeSettings}>
              <App authState={authState} authDispatch={authState.authDispatch} />
            </ConfigProvider>
          </BrowserRouter>
        )}
      </AuthConsumer>
    </AuthProvider>
  </StrictMode>
);
