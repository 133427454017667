import clsx from "clsx";

const Button = ({ type, className, label, onClick, disabled }) => {
  if (type === "action") {
    return (
      <button
        type="button"
        className={clsx(
          "text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 transition-all duration-200",
          className
        )}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {label}
      </button>
    );
  } else if (type === "secondary") {
    return (
      <button
        type="button"
        className={clsx(
          "py-1.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-gray-50 rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all duration-200",
          className
        )}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {label}
      </button>
    );
  } else if (type === "dark") {
    return (
      <button
        type="button"
        className={clsx(
          "text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 transition-all duration-200",
          className
        )}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {label}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        className={clsx(
          "text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transition-all duration-200",
          className
        )}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {label}
      </button>
    );
  }
};

export default Button;
