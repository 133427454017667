import {
  Arc3dCenterPoint,
  Bell,
  City,
  Dollar,
  Eye,
  LogOut,
  MagnetEnergy,
  Mail,
  Megaphone,
  Menu,
  Message,
  MultiWindow,
  NavArrowRight,
  NetworkRight,
  Reports,
  Settings,
} from "iconoir-react";
import Logo from "../assets/logo";
import dayjs from "dayjs";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Wrapper = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/login");
  };

  return (
    <div className="w-full h-full min-h-screen bg-gray-50">
      <div className="sticky inset-x-0 top-0 z-20 px-4 bg-black sm:px-6 md:px-8 lg:hidden">
        <div className="flex items-center py-4">
          <button type="button" className="text-gray-500 hover:text-gray-600" aria-controls="application-sidebar" aria-label="Toggle navigation">
            <span className="sr-only">Toggle Navigation</span>
            <Menu className="w-5 h-5 text-white" />
          </button>
          <ol className="flex items-center min-w-0 ml-3 whitespace-nowrap" aria-label="Breadcrumb">
            <li className="flex items-center text-sm text-gray-800 dark:text-gray-200">hyperook</li>
          </ol>
        </div>
      </div>
      <div
        id="application-sidebar"
        className="-translate-x-full transition-all duration-300 transform hidden fixed top-0 left-0 bottom-0 z-[60] w-64 bg-black border-r border-white pt-7 pb-10 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:right-auto lg:bottom-0"
      >
        <div className="px-8">
          <button className="flex justify-start flex-none text-xl font-semibold text-white" onClick={() => navigate("/")}>
            <Logo className="w-2/3 h-auto fill-white" />
          </button>
        </div>

        <nav className="flex flex-col flex-wrap w-full p-6 pt-5">
          <ul className="space-y-1.5">
            <li>
              <button className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full" onClick={() => navigate("/")}>
                <Arc3dCenterPoint className="w-5 h-5" />
                Dashboard
              </button>
            </li>
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/notifications")}
              >
                <Bell className="w-5 h-5" />
                Notifications
              </button>
            </li>
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/messages")}
              >
                <Message className="w-5 h-5" />
                Messages
              </button>
            </li>
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/submissions")}
              >
                <MultiWindow className="w-5 h-5" />
                Submissions
              </button>
            </li>
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/blasts")}
              >
                <Megaphone className="w-5 h-5" />
                Blasts
              </button>
            </li>
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/payments")}
              >
                <Dollar className="w-5 h-5" />
                Payments
              </button>
            </li>
            {/* <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/direct-mail")}
              >
                <Mail className="w-5 h-5" />
                Direct Mail
              </button>
            </li>
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/analytics")}
              >
                <Reports className="w-5 h-5" />
                Analytics
              </button>
            </li> */}
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/account")}
              >
                <City className="w-5 h-5" />
                Account
              </button>
            </li>
            {/* <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/automations")}
              >
                <NetworkRight className="w-5 h-5" />
                Automations
              </button>
            </li>
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/live-data")}
              >
                <Eye className="w-5 h-5" />
                Live Data
              </button>
            </li> */}
            <li>
              <button
                className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full"
                onClick={() => navigate("/settings")}
              >
                <Settings className="w-5 h-5" />
                Settings
              </button>
            </li>
            <li>
              <button className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-white rounded-md hover:bg-gray-950 w-full" onClick={() => logout()}>
                <LogOut className="w-5 h-5" />
                Sign Out
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <Outlet />
    </div>
  );
};

export default Wrapper;
