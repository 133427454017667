import React, { memo } from "react";
import { Handle, Position } from "reactflow";

function CustomNode({ data }) {
  return (
    <div className="px-4 py-2 rounded-md bg-slate-100 text-slate-800">
      <div className="flex">
        <p className="text-xs font-medium">{data.label}</p>
      </div>

      <Handle type="target" position={Position.Top} isConnectable={1} className="w-5 h-5 !bg-pink-500" />
      <Handle type="source" position={Position.Bottom} className="w-5 h-5 !bg-pink-500" />
    </div>
  );
}

export default memo(CustomNode);
