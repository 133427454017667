import React, { useState, useRef, useCallback } from "react";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import { Input, InputNumber, Modal, Select, Button as Btn, Switch, Slider } from "antd";
import toast from "react-hot-toast";
import { CartPlus, CheckCircle, Edit, MoreVert, NavArrowDown, NavArrowUp, PlusCircle, Trash, Xmark, XmarkCircle } from "iconoir-react";
import { BaseStepsAndFields, stepFieldTypes } from "./components/baseSteps";
import Map, { GeolocateControl } from "react-map-gl";
import DrawControl from "./components/drawControl";
import { AddressAutofill, config } from "@mapbox/search-js-react";
import { stateList } from "../../../components/stateList";
import * as turf from "@turf/turf";
import { MaskedInput } from "antd-mask-input";
import { v4 as uuidv4 } from "uuid";
import { formatCurrency } from "../../../components/utils";
import { CreateNewWidget } from "../../../actions/api";

const NewWidget = () => {
  const [loading, setLoading] = useState(false);
  const [dummyLoading, setDummyLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [widget, setWidget] = useState({
    title: "",
    enableTexting: false,
    ctaCard: false,
    popup: false,
    texting: {
      title: "",
      buttonText: "Text us NOW",
    },
    cta: {
      title: "",
      description: "",
      buttonText: "Get Started",
    },
    dialog: {
      title: "",
      description: "",
      buttonText: "Get Started",
      showAfter: 30,
      hideFor: 3,
    },
    notifications: {
      formCompleteText: true,
      formCompleteEmail: true,
      smsSubmitText: true,
      smsSubmitEmail: true,
      clientConfirmationText: true,
      clientConfirmationEmail: true,
    },
    ga4Id: "",
    pixelId: "",
  });
  const [steps, setSteps] = useState(BaseStepsAndFields);
  const [currentData, setCurrentData] = useState({
    index: null,
    isFirstStep: false,
    isLastStep: false,
    title: "",
    description: "",
    buttonText: "Next",
    stepType: null,
    fields: [],
    newStep: true,
    config: {
      bbox: [],
      phone: false,
      email: false,
      passFees: false,
      required: false,
      inputType: null,
      inputLabel: null,
    },
  });
  const [stepEditor, setStepEditor] = useState(false);
  const [boundingBoxModal, setBoundingBoxModal] = useState(false);
  const [address, setAddress] = useState({ address: "", address2: "", city: "", state: null, zipCode: "" });
  const [features, setFeatures] = useState({});
  const [upsellModal, setUpsellModal] = useState(false);
  const [upsellData, setUpsellData] = useState({
    label: "",
    value: "",
    image: null,
    price: null,
  });

  config.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

  const navigate = useNavigate();
  const geoLocateRef = useRef(null);

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const tabs = [
    { name: "Details", href: "/account", current: false },
    { name: "Payments", href: "/account/payments", current: false },
    { name: "Widgets", href: "/account/widgets", current: true },
    { name: "Integrations", href: "/account/integrations", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const addNewStep = (index) => {
    setCurrentData({
      index: index,
      isFirstStep: false,
      isLastStep: false,
      title: "",
      description: "",
      buttonText: "Next",
      stepType: null,
      fields: [],
      newStep: true,
      config: {
        bbox: [],
        phone: false,
        email: false,
        passFees: false,
        required: false,
        inputType: null,
        inputLabel: null,
      },
    });
    setTimeout(() => setStepEditor(true), 700);
  };

  const closeStepEditor = () => {
    setStepEditor(false);
    setCurrentData({
      index: null,
      isFirstStep: false,
      isLastStep: false,
      title: "",
      description: "",
      buttonText: "Next",
      stepType: null,
      fields: [],
      config: {
        bbox: [],
        phone: false,
        email: false,
        passFees: false,
        required: false,
        inputType: null,
        inputLabel: null,
      },
      newStep: true,
    });
    setFeatures({});
    setAddress({ address: "", address2: "", city: "", state: null, zipCode: "" });
    setBoundingBoxModal(false);
    setUpsellData({
      label: "",
      value: "",
      image: null,
      price: null,
    });
    setUpsellModal(false);
  };

  const submitNewStep = () => {
    let valid = true;
    let payload = currentData;
    if (currentData.stepType === "address") {
      if (currentData.title.length === 0) {
        valid = false;
        toast.error("Please enter a title for your step.");
      }
      if (currentData.description.length === 0) {
        valid = false;
        toast.error("Please enter a description for your step.");
      }
    }
    if (currentData.stepType === "contact") {
      if (currentData.title.length === 0) {
        valid = false;
        toast.error("Please enter a title for your step.");
      }
      if (!currentData.config.phone && !currentData.config.email) {
        valid = false;
        toast.error("Please select at least one contact method.");
      }
    }
    if (currentData.stepType === "single-select") {
      if (currentData.fields.length === 0 || !currentData.fields[0].options || currentData.fields[0].options.length === 0) {
        valid = false;
        toast.error("Invalid field setup");
      }
      let tmp = currentData.fields[0].options.filter((option) => option.length > 0);
      if (tmp.length < 2) {
        valid = false;
        toast.error("Please provide at least 2 possible answers for this step.");
      }
      if (tmp.length > 8) {
        valid = false;
        toast.error("Number of options exceeding the limit of 8 choices.");
      }
      payload.fields[0].options = tmp;
    }
    if (valid) {
      setDummyLoading(true);
      if (currentData.newStep) {
        const newSteps = [...steps.slice(0, currentData.index + 1), payload, ...steps.slice(currentData.index + 1)];
        setSteps(newSteps);
        toast.success("Step added successfully.");
      } else {
        let tmp = steps;
        tmp[currentData.index] = payload;
        setSteps(tmp);
        toast.success("Step updated successfully.");
      }
      closeStepEditor();
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const renderAddStep = (index) => {
    return (
      <div className="flex flex-col items-center justify-center h-fit">
        <MoreVert className="w-5 h-5" />
        <PlusCircle
          className="w-6 h-6 transition-all duration-150 cursor-pointer text-secondary-400 hover:text-secondary-900"
          onClick={() => addNewStep(index)}
        />
        <MoreVert className="w-5 h-5" />
      </div>
    );
  };

  const renderFormStep = (stepData, index) => {
    return (
      <>
        <div className="flex flex-col items-center justify-between w-full max-w-md p-5 bg-white border border-gray-200 rounded-lg shadow-lg h-fit">
          <p className="text-xs text-secondary-500">Step {index + 1}</p>
          <p className="text-lg font-semibold">{stepFieldTypes[stepData.stepType]}</p>
          <div className="inline-flex mt-5 overflow-hidden border border-gray-300 divide-x divide-gray-300 rounded-lg shadow-sm -space-x-0">
            <button
              type="button"
              className="px-2 py-1 text-sm font-medium text-center bg-white text-secondary-700 hover:bg-gray-100 disabled:text-secondary-400 disabled:hover:bg-white disabled:cursor-not-allowed"
              disabled={stepData.isFirstStep || stepData.isLastStep || steps[index - 1].isFirstStep}
            >
              <NavArrowUp className="w-5 h-5" />
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm font-medium text-center bg-white text-secondary-700 hover:bg-gray-100 disabled:text-secondary-400 disabled:hover:bg-white disabled:cursor-not-allowed"
              disabled={stepData.isFirstStep || stepData.isLastStep || steps[index + 1].isLastStep}
            >
              <NavArrowDown className="w-5 h-5" />
            </button>
            <button
              type="button"
              className="px-2 py-1 text-sm font-medium text-center bg-white text-secondary-700 hover:bg-gray-100 disabled:text-secondary-400 disabled:hover:bg-white disabled:cursor-not-allowed"
              disabled={stepData.isFirstStep || stepData.isLastStep}
            >
              <Trash className="w-4 h-4" />
            </button>
            <button
              onClick={() => editStep(stepData, index)}
              className="px-2 py-1 text-sm font-medium text-center bg-white text-secondary-700 hover:bg-gray-100 disabled:text-secondary-400 disabled:hover:bg-white disabled:cursor-not-allowed"
            >
              <Edit className="w-5 h-5" />
            </button>
          </div>
        </div>
        {!stepData.isLastStep && renderAddStep(index)}
      </>
    );
  };

  const editStep = (stepData, index) => {
    stepData.newStep = false;
    stepData.index = index;
    setCurrentData(stepData);
    setTimeout(() => setStepEditor(true), 700);
  };

  const submitBoundingBox = () => {
    let fin = [];
    for (let key in features) {
      let coords = features[key].geometry.coordinates[0];
      fin.push(coords);
    }
    if (fin.length > 0) {
      let tmp = currentData;
      tmp.config.bbox = fin;
      setCurrentData(tmp);
      setBoundingBoxModal(false);
      setFeatures({});
    } else {
      toast.error("Please draw a boundary for your service area.");
    }
  };

  const closeUpsellEditor = () => {
    setUpsellModal(false);
    setUpsellData({
      label: "",
      value: "",
      image: null,
      price: null,
    });
  };

  const addUpsell = () => {
    setDummyLoading(true);
    let payload = upsellData;
    if (upsellData.optionId) {
      let tmp = currentData;
      let tmp2 = tmp.fields[0].options;
      let index = tmp2.findIndex((x) => x.optionId === upsellData.optionId);
      tmp2[index] = payload;
      tmp.fields[0].options = tmp2;
      setCurrentData(tmp);
      closeUpsellEditor();
    } else {
      payload.optionId = uuidv4();
      let tmp = currentData;
      if (tmp.fields.length === 0) {
        tmp.fields = [{ fieldType: "addon", fieldId: uuidv4(), required: true, options: [payload] }];
        setCurrentData(tmp);
      } else {
        tmp.fields[0].options.push(payload);
        setCurrentData(tmp);
      }
      setCurrentData(tmp);
      closeUpsellEditor();
    }
    setTimeout(() => setDummyLoading(false), 700);
  };

  const openEditUpsell = (field) => {
    setUpsellData(field);
    setUpsellModal(true);
  };

  const removeUpsell = (optionId) => {
    setDummyLoading(true);
    let tmp = currentData;
    let tmp2 = tmp.fields[0].options;
    let filtered = tmp2.filter((x) => x.optionId !== optionId);
    tmp.fields[0].options = filtered;
    setCurrentData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderConfigForStep = (type) => {
    if (type === "address") {
      return (
        <>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Service Area</label>
            <Button label={currentData.config.bbox.length === 0 ? "Set Service Area" : "Redraw Service Area"} onClick={() => setBoundingBoxModal(true)} />
          </div>
          <Modal
            open={boundingBoxModal}
            onClose={() => setBoundingBoxModal(false)}
            onCancel={() => setBoundingBoxModal(false)}
            onOk={() => submitBoundingBox()}
            okText="Save Boundary"
            width="80%"
            title="Set Service Area Boundary"
            styles={{ body: { height: "80vh", minHeight: "600px", display: "flex" } }}
            centered
            destroyOnClose
          >
            <div className="flex items-center justify-center flex-1 w-full h-full">
              <Map
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                initialViewState={{
                  longitude: -122.4,
                  latitude: 37.8,
                  zoom: 11,
                }}
                maxZoom={11}
                style={{ width: "100%", height: "100%" }}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                onLoad={() => {
                  geoLocateRef.current.trigger();
                }}
              >
                <GeolocateControl ref={geoLocateRef} />
                <DrawControl
                  position="top-left"
                  displayControlsDefault={false}
                  controls={{
                    polygon: true,
                    trash: true,
                  }}
                  defaultMode="draw_polygon"
                  onCreate={onUpdate}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                />
              </Map>
            </div>
          </Modal>
        </>
      );
    } else if (type === "contact") {
      return (
        <>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700">Name</label>
            <Switch defaultChecked={true} disabled />
          </div>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700">Phone Number</label>
            <Switch defaultChecked={currentData.config.phone} onChange={(c) => updateBoolState(c, "phone")} />
          </div>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700">Email</label>
            <Switch defaultChecked={currentData.config.email} onChange={(c) => updateBoolState(c, "email")} />
          </div>
        </>
      );
    } else if (type === "single-select") {
      if (currentData.fields.length === 0) {
        setDummyLoading(true);
        setCurrentData({ ...currentData, fields: [{ fieldType: "single-select", fieldId: uuidv4(), required: true, options: [""] }] });
        setTimeout(() => setDummyLoading(false), 700);
      }
      return (
        <>
          <p className="block mt-3 mb-1 text-xs transition-all text-slate-500">
            Add your options below, once you finish building the form, you'll be able to add icons or pictures to represent each of them.
          </p>
          <div className="flex items-center justify-between w-full mt-1">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700">Options</label>
            <Button label="Add Option" onClick={() => addSelectOption()} />
          </div>
          {currentData.fields.length > 0 &&
            currentData.fields[0].options &&
            currentData.fields[0].options.length > 0 &&
            currentData.fields[0].options.map((field, index) => (
              <div className="flex items-center justify-between w-full gap-2 mt-0">
                <Input placeholder={`Option #${index + 1}`} defaultValue={field} onChange={(e) => updateOptionValue(e.target.value, index)} width={"100%"} />
                {index !== 0 && (
                  <Btn icon={<Trash className="w-4 h-4" />} className="flex items-center justify-center h-full" onClick={() => removeOption(index)} />
                )}
              </div>
            ))}
        </>
      );
    } else if (type === "multi-select") {
      if (currentData.fields.length === 0) {
        setDummyLoading(true);
        setCurrentData({ ...currentData, fields: [{ fieldType: "multi-select", fieldId: uuidv4(), required: true, options: [""] }] });
        setTimeout(() => setDummyLoading(false), 700);
      }
      return (
        <>
          <p className="block mt-3 mb-1 text-xs transition-all text-slate-500">
            Add your options below, once you finish building the form, you'll be able to add icons or pictures to represent each of them.
          </p>
          <div className="flex items-center justify-between w-full mt-1">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700">Options</label>
            <Button label="Add Option" onClick={() => addSelectOption()} />
          </div>
          {currentData.fields.length > 0 &&
            currentData.fields[0].options &&
            currentData.fields[0].options.length > 0 &&
            currentData.fields[0].options.map((field, index) => (
              <div className="flex items-center justify-between w-full gap-2 mt-0">
                <Input placeholder={`Option #${index + 1}`} defaultValue={field} onChange={(e) => updateOptionValue(e.target.value, index)} width={"100%"} />
                {index !== 0 && (
                  <Btn icon={<Trash className="w-4 h-4" />} className="flex items-center justify-center h-full" onClick={() => removeOption(index)} />
                )}
              </div>
            ))}
        </>
      );
    } else if (type === "boolean") {
      //
    } else if (type === "addon") {
      return (
        <>
          <div className="flex items-center justify-between w-full mt-2">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700">Your Upsells</label>
            <Button label="Add Upsell" onClick={() => setUpsellModal(true)} />
          </div>
          {currentData.fields.length > 0 &&
            currentData.fields[0].options &&
            currentData.fields[0].options.length > 0 &&
            currentData.fields[0].options.map((field, index) => (
              <div className="flex flex-row items-center justify-between w-full gap-2 p-2 mt-2 border border-gray-200 rounded-md">
                <div className="flex flex-col items-start justify-center gap-1">
                  <p className="text-sm font-medium text-gray-700">{field.label}</p>
                  <p className="text-xs font-semibold text-gray-500">{formatCurrency(field.price)}</p>
                </div>
                <div className="flex flex-col items-end justify-center gap-1">
                  <Edit className="w-5 h-5 transition-all cursor-pointer hover:text-blue-500" onClick={() => openEditUpsell(field)} />
                  <Trash className="w-5 h-5 transition-all cursor-pointer hover:text-red-500" onClick={() => removeUpsell(field.optionId)} />
                </div>
              </div>
            ))}
          <Modal
            open={upsellModal}
            onClose={() => closeUpsellEditor()}
            onCancel={() => closeUpsellEditor()}
            onOk={() => addUpsell()}
            okText="Save Upsell"
            width="50%"
            title="Add-On / Upsell Details"
            styles={{ body: { maxHeight: "600px", display: "flex" } }}
            centered
            destroyOnClose
          >
            <div className="flex flex-col items-center justify-center flex-1 w-full h-full">
              <div className="w-full mt-3 group">
                <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Add-On / Upsell Title</label>
                <Input
                  placeholder="Add-On / Upsell Title"
                  defaultValue={upsellData.label}
                  onChange={(e) => setUpsellData({ ...upsellData, label: e.target.value })}
                />
              </div>
              <div className="w-full mt-3 group">
                <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">
                  Add-On / Upsell Description
                </label>
                <Input.TextArea
                  placeholder="Add-On / Upsell Description"
                  defaultValue={upsellData.value}
                  onChange={(e) => setUpsellData({ ...upsellData, value: e.target.value })}
                  rows={3}
                  style={{ resize: "none" }}
                  maxLength={200}
                />
              </div>
              <div className="w-full mt-3 group">
                <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Add-On / Upsell Price</label>
                <InputNumber
                  placeholder="Add-On / Upsell Price"
                  defaultValue={upsellData.price}
                  onChange={(e) => setUpsellData({ ...upsellData, price: e })}
                  controls={false}
                  step={0.01}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </Modal>
        </>
      );
    } else if (currentData.stepType === "payment") {
      return (
        <>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Price</label>
            <InputNumber
              placeholder="Amount to collect"
              defaultValue={currentData.config.price}
              onChange={(e) => setCurrentData({ ...currentData, config: { ...currentData.config, price: e } })}
              controls={false}
              step={0.01}
              style={{ width: "100%" }}
            />
          </div>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-xs font-medium transition-all text-slate-700">Pass processing fees onto the customer</label>
            <Switch
              defaultChecked={currentData.config.passFees}
              onChange={(c) => setCurrentData({ ...currentData, config: { ...currentData.config, passFees: c } })}
            />
          </div>
        </>
      );
    } else if (currentData.stepType === "custom") {
      return (
        <>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-xs font-medium transition-all text-slate-700">Make this step required</label>
            <Switch
              defaultChecked={currentData.config.required}
              onChange={(c) => setCurrentData({ ...currentData, config: { ...currentData.config, required: c } })}
            />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Input Type</label>
            <Select
              defaultValue={currentData.config.inputType}
              placeholder="Select a Input Type"
              style={{ width: "100%" }}
              onChange={(value) => setCurrentData({ ...currentData, config: { ...currentData.config, inputType: value } })}
              options={[
                { value: "text", label: "Text Input" },
                { value: "textarea", label: "Large Text Input" },
                { value: "phone", label: "Phone Number Input" },
                { value: "email", label: "Email Address Inout" },
              ]}
            />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Input Label</label>
            <Input
              placeholder="Input Label"
              defaultValue={currentData.config.inputLabel}
              onChange={(e) => setCurrentData({ ...currentData, config: { ...currentData.config, inputLabel: e.target.value } })}
            />
          </div>
        </>
      );
    }
  };

  const removeOption = (index) => {
    setDummyLoading(true);
    let tmp = currentData;
    let tmp2 = tmp.fields[0].options;
    tmp2.splice(index, 1);
    tmp.fields[0].options = tmp2;
    setCurrentData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const updateOptionValue = (value, index) => {
    setDummyLoading(true);
    let tmp = currentData;
    tmp.fields[0].options[index] = value;
    setCurrentData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const addSelectOption = () => {
    if (currentData.fields[0].options.length > 8) {
      toast.error("You can only have a maximum of 8 options.");
    } else {
      setDummyLoading(true);
      let tmp = currentData;
      tmp.fields[0].options.push("");
      setCurrentData(tmp);
      setTimeout(() => setDummyLoading(false), 700);
    }
  };

  const updateBoolState = (value, field) => {
    setDummyLoading(true);
    let tmp = currentData;
    tmp.config[field] = value;
    setCurrentData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const updateAddressFields = (values) => {
    if (currentData.config.bbox.length === 0) {
      setAddress({
        address: values.features[0].properties.address_line1,
        address2: address.address2,
        city: values.features[0].properties.place,
        state: values.features[0].properties.address_level1,
        zipCode: values.features[0].properties.postcode,
      });
    } else {
      let boundary = turf.polygon(currentData.config.bbox);
      let addressPoint = turf.point(values.features[0].geometry.coordinates);
      let isInside = turf.booleanPointInPolygon(addressPoint, boundary);
      if (isInside) {
        setAddress({
          address: values.features[0].properties.address_line1,
          address2: address.address2,
          city: values.features[0].properties.place,
          state: values.features[0].properties.address_level1,
          zipCode: values.features[0].properties.postcode,
        });
      } else {
        setAddress({
          address: "",
          address2: "",
          city: "",
          state: null,
          zipCode: "",
        });
        toast.error("The provided address is outside of your service area.");
      }
    }
  };

  const renderStepPreview = () => {
    if (currentData.stepType === "address") {
      return (
        <div className="flex flex-col items-center justify-center w-full">
          <AddressAutofill accessToken={process.env.REACT_APP_MAPBOX_TOKEN} onRetrieve={(v) => updateAddressFields(v)}>
            <div className="w-full mt-3 group">
              <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Address</label>
              <input
                name="address"
                placeholder="Start typing your address, e.g. 123 Main St"
                type="text"
                autoComplete="address-line1"
                className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
                value={address.address}
                onChange={(e) => setAddress({ ...address, address: e.target.value })}
              />
            </div>
          </AddressAutofill>
          <div className="w-full max-w-md mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Apt/Suite/Unit</label>
            <input
              name="address2"
              placeholder="Apartment, suite, unit, building, floor, etc."
              type="text"
              autoComplete="address-line2"
              className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
              value={address.address2}
              onChange={(e) => setAddress({ ...address, address2: e.target.value })}
            />
          </div>
          <div className="w-full max-w-md mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">City</label>
            <input
              name="city"
              placeholder="City"
              type="text"
              autoComplete="address-level2"
              className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
              value={address.city}
              onChange={(v) => setAddress({ ...address, state: v })}
            />
          </div>
          <div className="flex items-center justify-between w-full max-w-md gap-3 mt-3">
            <div className="w-1/2 group">
              <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">State</label>
              <Select placeholder="Select a State" style={{ width: "100%" }} options={stateList} value={address.state} />
            </div>
            <div className="w-1/2 group">
              <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">ZIP Code</label>
              <input
                name="zipCode"
                placeholder="ZIP Code"
                type="text"
                maxLength={5}
                autoComplete="address-level2"
                className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
                value={address.zipCode}
                onChange={(e) => setAddress({ ...address, zipCode: e.target.value })}
              />
            </div>
          </div>
        </div>
      );
    } else if (currentData.stepType === "contact") {
      return (
        <div className="flex flex-col items-center justify-center w-full">
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Name</label>
            <input
              name="name"
              placeholder="Your Name"
              type="text"
              autoComplete="name"
              className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
            />
          </div>
          {currentData.config.phone && (
            <div className="w-full mt-3 group">
              <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Phone Number</label>
              <MaskedInput
                mask={"(000) 000-0000"}
                placeholder="Your Phone Number"
                className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
              />
            </div>
          )}
          {currentData.config.email && (
            <div className="w-full mt-3 group">
              <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Email Address</label>
              <input
                name="email"
                placeholder="Your Email Address"
                type="email"
                autoComplete="email"
                className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
              />
            </div>
          )}
        </div>
      );
    } else if (currentData.stepType === "single-select") {
      let colNo = "1";
      if (currentData.fields.length > 0 && currentData.fields[0].options && currentData.fields[0].options.length > 0) {
        if (currentData.fields[0].options.length >= 4) {
          colNo = "4";
        } else if (currentData.fields[0].options.length === 3) {
          colNo = "3";
        } else if (currentData.fields[0].options.length === 2) {
          colNo = "2";
        } else {
          colNo = "1";
        }
      }
      return (
        <div className={`grid grid-cols-${colNo} gap-2 w-full`}>
          {currentData.fields.length > 0 &&
            currentData.fields[0].options &&
            currentData.fields[0].options.length > 0 &&
            currentData.fields[0].options.map((option) => (
              <div className="flex items-center justify-center w-full h-24 col-span-1 px-5 transition-all border border-gray-200 cursor-pointer py-7 rounded-xl hover:shadow-lg hover:border-blue-200">
                <p className="font-medium text-gray-700">{option}</p>
              </div>
            ))}
        </div>
      );
    } else if (currentData.stepType === "multi-select") {
      let colNo = "1";
      if (currentData.fields.length > 0 && currentData.fields[0].options && currentData.fields[0].options.length > 0) {
        if (currentData.fields[0].options.length >= 4) {
          colNo = "4";
        } else if (currentData.fields[0].options.length === 3) {
          colNo = "3";
        } else if (currentData.fields[0].options.length === 2) {
          colNo = "2";
        } else {
          colNo = "1";
        }
      }
      return (
        <div className={`grid grid-cols-${colNo} gap-2 w-full`}>
          {currentData.fields.length > 0 &&
            currentData.fields[0].options &&
            currentData.fields[0].options.length > 0 &&
            currentData.fields[0].options.map((option, index) => (
              <div className="relative flex w-full col-span-1">
                <input type="checkbox" id={index} className="absolute hidden peer" />
                <label
                  for={index}
                  className="flex items-center justify-center w-full h-24 font-medium text-gray-700 transition-colors duration-150 ease-in-out border cursor-pointer select-none rounded-xl peer-checked:border-blue-500 peer-checked:text-blue-500 peer-hover:border-blue-200"
                >
                  {option}
                </label>
              </div>
            ))}
        </div>
      );
    } else if (currentData.stepType === "boolean") {
      return (
        <div className="flex items-center justify-center w-full gap-5">
          <div className="flex flex-col items-center justify-center w-1/5 gap-4 transition-all border border-gray-300 cursor-pointer h-36 rounded-xl hover:border-green-300 hover:text-green-500">
            <CheckCircle className="w-6 h-6 text-green-500" />
            <p className="font-medium">YES</p>
          </div>
          <div className="flex flex-col items-center justify-center w-1/5 gap-4 transition-all border border-gray-300 cursor-pointer h-36 rounded-xl hover:border-red-300 hover:text-red-500">
            <XmarkCircle className="w-6 h-6 text-red-500" />
            <p className="font-medium">YES</p>
          </div>
        </div>
      );
    } else if (currentData.stepType === "addon") {
      return (
        currentData.fields.length > 0 &&
        currentData.fields[0].options.length > 0 && (
          <div className="flex flex-col items-center justify-center w-full gap-2">
            {currentData.fields[0].options.map((option) => (
              <div className="flex flex-col items-center justify-between w-full gap-2 px-3 py-5 border border-gray-200 rounded-xl">
                <div className="flex items-center justify-between w-full pb-3 border-b border-gray-200">
                  <div className="flex items-center justify-start gap-1">
                    <CartPlus className="w-5 h-5 text-gray-800" />
                    <p className="text-base font-medium text-black">{option.label}</p>
                  </div>
                  <p className="text-xs font-medium text-gray-500">{formatCurrency(option.price)}</p>
                </div>
                <p className="w-full text-xs">{option.value}</p>
                <div className="flex items-center justify-end w-full mt-1">
                  <div class="flex">
                    <input type="checkbox" id={option.optionId} class="peer hidden" />
                    <label
                      for={option.optionId}
                      class="select-none cursor-pointer rounded-lg py-1.5 px-5 font-semibold text-white transition-colors duration-200 ease-in-out peer-checked:bg-blue-800 bg-blue-600 peer-checked:hidden block"
                    >
                      Add
                    </label>
                    <label
                      for={option.optionId}
                      class="select-none cursor-pointer rounded-lg py-1.5 px-5 font-semibold text-white transition-colors duration-200 ease-in-out peer-checked:bg-blue-800 bg-blue-600 peer-checked:block hidden"
                    >
                      Added
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      );
    } else if (currentData.stepType === "custom") {
      if (currentData.config.inputType === "text") {
        return (
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">
              {currentData.config.inputLabel}
            </label>
            <Input placeholder={currentData.config.inputLabel} />
          </div>
        );
      } else if (currentData.config.inputType === "textarea") {
        return (
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">
              {currentData.config.inputLabel}
            </label>
            <Input.TextArea placeholder={currentData.config.inputLabel} rows={3} style={{ resize: "none" }} />
          </div>
        );
      } else if (currentData.config.inputType === "phone") {
        return (
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">
              {currentData.config.inputLabel}
            </label>
            <MaskedInput
              mask={"(000) 000-0000"}
              placeholder={currentData.config.inputLabel}
              className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
            />
          </div>
        );
      } else if (currentData.config.inputType === "email") {
        return (
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">
              {currentData.config.inputLabel}
            </label>
            <input
              name="email"
              placeholder={currentData.config.inputLabel}
              type="email"
              autoComplete="email"
              className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 placeholder:text-gray-400"
            />
          </div>
        );
      }
    }
  };

  const renderStep = () => {
    if (step === 0) {
      return (
        <div className="flex flex-col items-center justify-around w-full h-full max-w-4xl">
          <div className="flex flex-col items-center justify-start w-full gap-4">
            <h1 className="mb-5 text-xl font-bold">Widget Label</h1>
            <div className="w-full max-w-lg mx-auto">
              <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 pl-0.5">
                Widget Title
              </label>
              <input
                type="email"
                id="title"
                className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
                placeholder="Lead Capture Form"
                onChange={(e) => setWidget({ ...widget, title: e.target.value })}
              />
              <p className="mt-1.5 text-xs text-gray-500 pl-0.5">This is an internal label for the widget.</p>
            </div>
            {/* <div className="flex items-center justify-between w-full max-w-lg mx-auto">
              <div className="flex flex-row items-center justify-start gap-1">
                <p className="text-sm font-medium text-gray-900">Enable SMS Lead Capture</p>
                <MoreHorizCircle
                  className="h-4 text-gray-500 transition-all duration-150 cursor-pointer hover:text-black"
                  onClick={() => setChatDetails(true)}
                />
              </div>
              <label htmlFor="enableTexting" className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" id="enableTexting" className="sr-only peer" onChange={(e) => setData({ ...data, enableTexting: e.target.checked })} />
                <div className="h-6 w-11 rounded-full bg-gray-300 after:absolute after:top-0.5 after:left-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:shadow after:transition-all after:content-[''] hover:bg-gray-200 peer-checked:bg-primary-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-primary-200 peer-disabled:cursor-not-allowed peer-disabled:bg-gray-100 peer-disabled:after:bg-gray-50"></div>
              </label>
            </div>
            <div className="flex items-center justify-between w-full max-w-lg mx-auto">
              <div className="flex flex-row items-center justify-start gap-1">
                <p className="text-sm font-medium text-gray-900">Enable Call-to-Action Card</p>
                <MoreHorizCircle
                  className="h-4 text-gray-500 transition-all duration-150 cursor-pointer hover:text-black"
                  onClick={() => setCardCTADetails(true)}
                />
              </div>
              <label htmlFor="bottomLeftCard" className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" id="bottomLeftCard" className="sr-only peer" onChange={(e) => setData({ ...data, ctaCard: e.target.checked })} />
                <div className="h-6 w-11 rounded-full bg-gray-300 after:absolute after:top-0.5 after:left-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:shadow after:transition-all after:content-[''] hover:bg-gray-200 peer-checked:bg-primary-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-primary-200 peer-disabled:cursor-not-allowed peer-disabled:bg-gray-100 peer-disabled:after:bg-gray-50"></div>
              </label>
            </div>
            <div className="flex items-center justify-between w-full max-w-lg mx-auto">
              <div className="flex flex-row items-center justify-start gap-1">
                <p className="text-sm font-medium text-gray-900">Enable Popup Reminders</p>
                <MoreHorizCircle
                  className="h-4 text-gray-500 transition-all duration-150 cursor-pointer hover:text-black"
                  onClick={() => setPopupDetails(true)}
                />
              </div>
              <label htmlFor="popup" className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" id="popup" className="sr-only peer" onChange={(e) => setData({ ...data, popup: e.target.checked })} />
                <div className="h-6 w-11 rounded-full bg-gray-300 after:absolute after:top-0.5 after:left-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:shadow after:transition-all after:content-[''] hover:bg-gray-200 peer-checked:bg-primary-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-primary-200 peer-disabled:cursor-not-allowed peer-disabled:bg-gray-100 peer-disabled:after:bg-gray-50"></div>
              </label>
              </div>
              {renderDetailsDialogs()} */}
          </div>
          <div className="flex items-center justify-end w-full">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="flex flex-col items-center justify-around w-full mt-5">
          <h1 className="mb-2 text-xl font-bold">Form</h1>
          <div className="flex flex-col items-center justify-center flex-grow w-full gap-2 mt-4 overflow-y-auto">
            {steps.map((step, index) => renderFormStep(step, index))}
          </div>
          <Modal
            open={stepEditor}
            onClose={() => closeStepEditor()}
            onCancel={() => closeStepEditor()}
            onOk={() => submitNewStep()}
            width="80%"
            centered
            okText={currentData.newStep ? "Add Step" : "Save Changes"}
            cancelText="Cancel"
            title={currentData.newStep ? "New Step" : "Edit Step"}
            styles={{ body: { minHeight: "600px", padding: "20px 0", display: "flex" } }}
            destroyOnClose
          >
            <div className="grid flex-1 w-full grid-cols-3 divide-x divide-gray-400 gap-x-3">
              <div className="flex w-full h-full col-span-2 max-h-[560px]">
                <div className="flex flex-col items-center justify-between flex-1 p-6 m-3 bg-white shadow-xl rounded-xl">
                  <div className="flex items-center justify-end w-full">
                    <Xmark className="w-6 h-6 text-gray-400 transition-all cursor-pointer hover:text-gray-700" />
                  </div>
                  {currentData.stepType !== "info" ? (
                    <div className="flex flex-col items-center justify-start w-full h-full">
                      <h1 className="text-xl font-semibold text-slate-950">{currentData.title.length > 0 ? currentData.title : "Step Title"}</h1>
                      <h1 className="text-sm text-black/40 mt-0.5">{currentData.title.length > 0 ? currentData.description : "Step Description"}</h1>
                      <div className="flex flex-col items-center justify-center flex-1 w-full h-full max-w-2xl">{renderStepPreview()}</div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center w-full h-full gap-2">
                      <h1 className="text-2xl font-semibold text-slate-950">{currentData.title.length > 0 ? currentData.title : "Title"}</h1>
                      <h1 className="text-base text-black/45 mt-0.5">{currentData.title.length > 0 ? currentData.description : "Description"}</h1>
                    </div>
                  )}
                  {currentData.stepType !== "single-select" && currentData.stepType !== "boolean" && (
                    <Button label={currentData.buttonText} onClick={() => {}} />
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start justify-start w-full h-full col-span-1 gap-2 pl-3 overflow-y-auto max-h-[560px]">
                <h1 className="text-base font-medium text-secondary-700">Form Settings</h1>
                <div className="w-full mt-3 group">
                  <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Step Title</label>
                  <Input
                    placeholder="Step Title"
                    defaultValue={currentData.title}
                    onChange={(e) => setCurrentData({ ...currentData, title: e.target.value })}
                  />
                </div>
                <div className="w-full mt-3 group">
                  <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Step Description</label>
                  <Input
                    placeholder="Step Description"
                    defaultValue={currentData.description}
                    onChange={(e) => setCurrentData({ ...currentData, description: e.target.value })}
                  />
                </div>
                <div className="w-full mt-3 group">
                  <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Step Type</label>
                  <Select
                    defaultValue={currentData.stepType}
                    placeholder="Select a Step Type"
                    style={{ width: "100%" }}
                    onChange={(value) => setCurrentData({ ...currentData, stepType: value })}
                    options={[
                      { value: "address", label: "Service Address and Coverage" },
                      { value: "contact", label: "Contact Information" },
                      { value: "boolean", label: "Yes/No Question" },
                      { value: "single-select", label: "Single Choice Question" },
                      { value: "multi-select", label: "Multi Choice Question" },
                      { value: "custom", label: "Custom Step" },
                      { value: "payment", label: "Payment Capture" },
                      { value: "addon", label: "Add-On / Upsell" },
                      { value: "info", label: "Info Step" },
                    ]}
                  />
                </div>
                {currentData.stepType ? (
                  renderConfigForStep(currentData.stepType)
                ) : (
                  <p className="w-full pt-3 mt-4 text-sm text-center border-t border-gray-200 text-gray-500/80">
                    Please select a step type in order to continue
                  </p>
                )}
              </div>
            </div>
          </Modal>
          <div className="flex items-center justify-end w-full mt-2 mb-5">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="flex flex-col items-start justify-center w-full max-w-2xl mt-5">
          <h1 className="w-full mb-5 text-2xl font-semibold text-center">Widget addons</h1>
          <h1 className="mt-3 text-lg font-medium">SMS Texting Widget</h1>
          <p className="mt-1 text-sm text-gray-600">
            Enable a widget that will collect visitor's phone numbers and allow you to text with them from the hyperook dashboard.
          </p>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-sm font-medium transition-all text-slate-700">Enable SMS Texting</label>
            <Switch defaultChecked={currentData.config.required} onChange={(c) => setWidget({ ...widget, enableTexting: c })} />
          </div>
          <h1 className="mt-5 text-lg font-medium">CTA Card</h1>
          <p className="mt-1 text-sm text-gray-600">
            Enable a fixed call-to-action card in the bottom left corner of your website that will open the form when clicked.
          </p>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-sm font-medium transition-all text-slate-700">Enable CTA Card</label>
            <Switch defaultChecked={currentData.config.required} onChange={(c) => setWidget({ ...widget, ctaCard: c })} />
          </div>
          <h1 className="mt-5 text-lg font-medium">Popup Dialog</h1>
          <p className="mt-1 text-sm text-gray-600">
            Enable a dialog that will popup on your website after a visitor has been on your website for a certain amount of time, prompting them to fill out
            the form.
          </p>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-sm font-medium transition-all text-slate-700">Enable Dialog</label>
            <Switch defaultChecked={currentData.config.required} onChange={(c) => setWidget({ ...widget, popup: c })} />
          </div>
          <div className="flex items-center justify-end w-full mt-10 mb-5">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="flex flex-col items-start justify-center w-full max-w-2xl mt-5">
          <h1 className="w-full mb-5 text-2xl font-semibold text-center">SMS Texting Widget Settings</h1>
          <h1 className="mt-3 text-lg font-medium">Configure your texting widget</h1>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Texting Widget Title</label>
            <Input
              placeholder="Get answers, fast!"
              onChange={(e) => setWidget({ ...widget, texting: { ...widget.texting, title: e.target.value } })}
              id="text-title"
            />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Texting Widget Button Text</label>
            <Input
              placeholder="Text with us, NOW!"
              id="text-cta"
              onChange={(e) => setWidget({ ...widget, texting: { ...widget.texting, buttonText: e.target.value } })}
              defaultValue={widget.texting.buttonText}
            />
          </div>
          <div className="flex items-center justify-end w-full mt-10 mb-5">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="flex flex-col items-start justify-center w-full max-w-2xl mt-5">
          <h1 className="w-full mb-5 text-2xl font-semibold text-center">CTA Card Settings</h1>
          <h1 className="mt-3 text-lg font-medium">Configure your call-to-action card</h1>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Card Title</label>
            <Input onChange={(e) => setWidget({ ...widget, cta: { ...widget.cta, title: e.target.value } })} id="cta-title" />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Card Description</label>
            <Input onChange={(e) => setWidget({ ...widget, cta: { ...widget.cta, description: e.target.value } })} id="cta-description" />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Card Button Text</label>
            <Input
              onChange={(e) => setWidget({ ...widget, cta: { ...widget.cta, buttonText: e.target.value } })}
              defaultValue={widget.cta.buttonText}
              id="cta-cta"
            />
          </div>
          <div className="flex items-center justify-end w-full mt-10 mb-5">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 5) {
      return (
        <div className="flex flex-col items-start justify-center w-full max-w-2xl mt-5">
          <h1 className="w-full mb-5 text-2xl font-semibold text-center">Popup Dialog Settings</h1>
          <h1 className="mt-3 text-lg font-medium">Configure your popup dialog</h1>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Dialog Title</label>
            <Input onChange={(e) => setWidget({ ...widget, dialog: { ...widget.dialog, title: e.target.value } })} id="popup-title" />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Dialog Description</label>
            <Input onChange={(e) => setWidget({ ...widget, dialog: { ...widget.dialog, description: e.target.value } })} id="popup-description" />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Dialog Button Text</label>
            <Input
              onChange={(e) => setWidget({ ...widget, dialog: { ...widget.dialog, buttonText: e.target.value } })}
              defaultValue={widget.dialog.buttonText}
              id="popup-cta"
            />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">
              Show popup after {widget.dialog.showAfter} seconds
            </label>
            <Slider
              onChange={(e) => setWidget({ ...widget, dialog: { ...widget.dialog, showAfter: e } })}
              defaultValue={widget.dialog.showAfter}
              min={10}
              max={60}
            />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">
              After showing, don't trigger the popup for the next {widget.dialog.hideFor} day{widget.dialog.hideFor > 1 && "s"}
            </label>
            <Slider
              onChange={(e) => setWidget({ ...widget, dialog: { ...widget.dialog, hideFor: e } })}
              defaultValue={widget.dialog.hideFor}
              min={1}
              max={10}
            />
          </div>
          <div className="flex items-center justify-end w-full mt-10 mb-5">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 6) {
      return (
        <div className="flex flex-col items-start justify-center w-full max-w-2xl mt-5">
          <h1 className="w-full mb-5 text-2xl font-semibold text-center">Additional Settings</h1>
          <h1 className="mt-3 text-lg font-medium">Track your visitors and their behavior</h1>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Google Analytics 4 ID</label>
            <Input onChange={(e) => setWidget({ ...widget, cta: { ...widget.cta, ga4Id: e.target.value } })} id="GA4-ID" maxLength={12} />
          </div>
          <div className="w-full mt-3 group">
            <label className="block mb-1 text-xs font-medium transition-all text-slate-700 group-focus-within:text-slate-950">Facebook Pixel ID</label>
            <Input onChange={(e) => setWidget({ ...widget, cta: { ...widget.cta, pixelId: e.target.value } })} id="fb-pixel-ID" maxLength={15} />
          </div>
          <div className="flex items-center justify-end w-full mt-10 mb-5">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    } else if (step === 7) {
      return (
        <div className="flex flex-col items-start justify-center w-full max-w-2xl mt-5">
          <h1 className="w-full mb-5 text-2xl font-semibold text-center">Notification Settings</h1>
          <h1 className="mt-3 text-lg font-medium">Manage notifications for different events</h1>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-sm font-medium transition-all text-slate-700">Send me a text message when a form is filled out</label>
            <Switch
              defaultChecked={widget.notifications.formCompleteText}
              onChange={(c) => setWidget({ ...widget, notifications: { ...widget.notifications, formCompleteText: c } })}
            />
          </div>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-sm font-medium transition-all text-slate-700">Send me an email when a form is filled out</label>
            <Switch
              defaultChecked={widget.notifications.formCompleteEmail}
              onChange={(c) => setWidget({ ...widget, notifications: { ...widget.notifications, formCompleteEmail: c } })}
            />
          </div>
          {widget.enableTexting && (
            <>
              <div className="flex items-center justify-between w-full mt-3">
                <label className="block text-sm font-medium transition-all text-slate-700">Send me a text message when a new SMS request comes in</label>
                <Switch
                  defaultChecked={widget.notifications.smsSubmitText}
                  onChange={(c) => setWidget({ ...widget, notifications: { ...widget.notifications, smsSubmitText: c } })}
                />
              </div>
              <div className="flex items-center justify-between w-full mt-3">
                <label className="block text-sm font-medium transition-all text-slate-700">Send me an email when a new SMS request comes in</label>
                <Switch
                  defaultChecked={widget.notifications.smsSubmitEmail}
                  onChange={(c) => setWidget({ ...widget, notifications: { ...widget.notifications, smsSubmitEmail: c } })}
                />
              </div>
            </>
          )}
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-sm font-medium transition-all text-slate-700">Send the visitor an email confirmation upon completing the form</label>
            <Switch
              defaultChecked={widget.notifications.clientConfirmationEmail}
              onChange={(c) => setWidget({ ...widget, notifications: { ...widget.notifications, clientConfirmationEmail: c } })}
            />
          </div>
          <div className="flex items-center justify-between w-full mt-3">
            <label className="block text-sm font-medium transition-all text-slate-700">
              Send the visitor a confirmation text message upon completing the form
            </label>
            <Switch
              defaultChecked={widget.notifications.clientConfirmationText}
              onChange={(c) => setWidget({ ...widget, notifications: { ...widget.notifications, clientConfirmationText: c } })}
            />
          </div>
          <div className="flex items-center justify-end w-full mt-10 mb-5">
            <Button type="dark" label="Next" onClick={() => nextStep()} />
          </div>
        </div>
      );
    }
  };

  const nextStep = () => {
    if (step === 0) {
      if (widget.title === "") {
        toast.error("Please enter a title for your widget.");
      } else {
        setStep(1);
      }
    } else if (step === 1) {
      let addonsExist = steps.find((s) => s.stepType === "addon");
      let paymentExists = steps.find((s) => s.stepType === "payment");
      if (addonsExist) {
        if (addonsExist && paymentExists) {
          if (paymentExists.config.price && paymentExists.config.price > 0) {
            setStep(2);
          } else {
            toast.error("You must have a price for your payment step");
          }
        } else if (addonsExist && !paymentExists) {
          toast.error("You must have at a payment step if you're offering addons.");
        }
      } else if (paymentExists) {
        if (paymentExists.config.price && paymentExists.config.price > 0) {
          setStep(2);
        } else {
          toast.error("You must have a price for your payment step");
        }
      } else {
        setStep(2);
      }
    } else if (step === 2) {
      if (widget.enableTexting) {
        setStep(3);
      } else if (widget.ctaCard) {
        setStep(4);
      } else if (widget.popup) {
        setStep(5);
      } else {
        setStep(6);
      }
    } else if (step === 3) {
      if (widget.texting.title === "") {
        toast.error("Please enter a title for your texting widget.");
      } else if (widget.texting.buttonText === "") {
        toast.error("Please enter a button text for your texting widget.");
      } else if (widget.ctaCard) {
        setStep(4);
      } else if (widget.popup) {
        setStep(5);
      } else {
        setStep(6);
      }
    } else if (step === 4) {
      if (widget.cta.title === "") {
        toast.error("Please enter a title for your call-to-action card.");
      } else if (widget.cta.description === "") {
        toast.error("Please enter a description for your call-to-action card.");
      } else if (widget.cta.buttonText === "") {
        toast.error("Please enter a button text for your call-to-action card.");
      } else if (widget.popup) {
        setStep(5);
      } else {
        setStep(6);
      }
    } else if (step === 5) {
      if (widget.dialog.title === "") {
        toast.error("Please enter a title for your popup dialog.");
      } else if (widget.dialog.description === "") {
        toast.error("Please enter a description for your popup dialog.");
      } else if (widget.dialog.buttonText === "") {
        toast.error("Please enter a button text for your popup dialog.");
      } else {
        setStep(6);
      }
    } else if (step === 6) {
      if (widget.ga4Id === "" && widget.pixelId === "") {
        setStep(7);
      } else if (widget.ga4Id !== "" || widget.pixelId === "") {
        let failed = false;
        if (widget.ga4Id !== "" && widget.ga4Id.length !== 12) {
          failed = true;
          toast.error("Please enter a valid Google Analytics 4 ID.");
        }
        if (widget.pixelId !== "" && widget.pixelId.length !== 15) {
          failed = true;
          toast.error("Please enter a valid Facebook Pixel ID.");
        }
        if (!failed) {
          setStep(7);
        }
      }
    } else if (step === 7) {
      let payload = widget;
      payload.steps = steps;
      setLoading(true);
      CreateNewWidget(payload)
        .then((res) => {
          toast.success("Widget created successfully!");
          navigate(`/account/widgets/${res.data.widgetId}`);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while creating your widget.");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full gap-4 px-4 pt-10 sm:px-6 md:px-8 lg:pl-72">
      <div className="w-full pb-5 border-b border-gray-200">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-xl font-bold leading-6 text-gray-900">New Widget</h3>
          <Button type="secondary" label="Back to Widgets" onClick={() => navigate("/account/widgets")} />
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-2">
        <ol className="items-center space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
          <li className={classNames(step === 0 ? "text-blue-600" : "text-gray-500", "flex items-center space-x-2.5")}>
            <span
              className={classNames(
                step === 0 ? "border-blue-600" : "border-gray-500",
                "flex items-center justify-center w-8 h-8 border rounded-full shrink-0"
              )}
            >
              1
            </span>
            <span>
              <h3 className="text-sm font-medium leading-tight">Widget Label</h3>
              <p className="text-xs">Internal widget name</p>
            </span>
          </li>
          <li className={classNames(step === 1 ? "text-blue-600" : "text-gray-500", "flex items-center space-x-2.5")}>
            <span
              className={classNames(
                step === 1 ? "border-blue-600" : "border-gray-500",
                "flex items-center justify-center w-8 h-8 border rounded-full shrink-0"
              )}
            >
              2
            </span>
            <span>
              <h3 className="text-sm font-medium leading-tight">Form</h3>
              <p className="text-xs">Steps and Questions</p>
            </span>
          </li>
          <li className={classNames(step > 1 && step < 6 ? "text-blue-600" : "text-gray-500", "flex items-center space-x-2.5")}>
            <span
              className={classNames(
                step > 1 && step < 6 ? "border-blue-600" : "border-gray-500",
                "flex items-center justify-center w-8 h-8 border rounded-full shrink-0"
              )}
            >
              3
            </span>
            <span>
              <h3 className="text-sm font-medium leading-tight">Addons</h3>
              <p className="text-xs">Chat, CTA Card & Popup</p>
            </span>
          </li>
          <li className={classNames(step === 6 ? "text-blue-600" : "text-gray-500", "flex items-center space-x-2.5")}>
            <span
              className={classNames(
                step === 6 ? "border-blue-600" : "border-gray-500",
                "flex items-center justify-center w-8 h-8 border rounded-full shrink-0"
              )}
            >
              4
            </span>
            <span>
              <h3 className="text-sm font-medium leading-tight">Settings</h3>
              <p className="text-xs">Additional Configuration</p>
            </span>
          </li>
          <li className={classNames(step === 7 ? "text-blue-600" : "text-gray-500", "flex items-center space-x-2.5")}>
            <span
              className={classNames(
                step === 7 ? "border-blue-600" : "border-gray-500",
                "flex items-center justify-center w-8 h-8 border rounded-full shrink-0"
              )}
            >
              5
            </span>
            <span>
              <h3 className="text-sm font-medium leading-tight">Notifications</h3>
              <p className="text-xs">Confirmations and Alerts</p>
            </span>
          </li>
        </ol>
      </div>
      <div className="flex flex-col items-center justify-center flex-grow w-full content">{renderStep()}</div>
    </div>
  );
};

export default NewWidget;
