import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import CoreLayout from "./layouts/core";
import Login from "./views/auth/login";
import { GetUser } from "./actions/auth";
import { Player } from "@lottiefiles/react-lottie-player";
import FunnelLoading from "./assets/funnelLoading.json";

const App = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      GetUser()
        .then((response) => {
          authDispatch({
            type: "valid-login",
            payload: {
              token: localStorage.getItem("auth-token"),
              role: response.data.role,
              user: response.data,
            },
          });
          setLoading(false);
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-4 bg-black">
      <Player src={FunnelLoading} autoplay loop className="w-20 h-20" />
      <p className="text-sm font-light tracking-widest uppercase font-body animate-pulse">Loading</p>
    </div>
  ) : (
    <div className="w-full h-screen overflow-x-hidden">
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerclassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 5000,
          style: {
            background: "#000000",
            color: "#fff",
            borderRadius: "5px",
          },
          className: "maxZIndex",
          success: {
            style: {
              paddingLeft: "15px",
            },
          },
          error: {
            style: {
              paddingLeft: "15px",
            },
          },
        }}
      />
      <Routes>
        <Route path="login" element={<Login authDispatch={authDispatch} authState={authState} />} />
        {/* <Route path="signIn" element={<Login authDispatch={authDispatch} authState={authState} />} />
        <Route path="signUp" element={<Register authDispatch={authDispatch} authState={authState} />} />
        <Route path="forgotPassword" element={<ForgotPassword authDispatch={authDispatch} authState={authState} />} />
        <Route path="resetPassword" element={<ResetPassword authDispatch={authDispatch} authState={authState} />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy authDispatch={authDispatch} authState={authState} />} />
        <Route path="termsAndConditions" element={<TermsAndConditions authDispatch={authDispatch} authState={authState} />} />
        <Route path="eula" element={<EULA authDispatch={authDispatch} authState={authState} />} /> */}
        <Route path="/*" element={authState.isAuth ? <CoreLayout authState={authState} authDispatch={authDispatch} /> : <Navigate to="/login" />} />
      </Routes>
    </div>
  );
};

export default App;
