import { useState, useEffect } from "react";
import { GetLeads } from "../../actions/leads";
import { useNavigate, useParams } from "react-router";
import { GetSubmission } from "../../actions/submissions";
import dayjs from "dayjs";
import { Button, Popover } from "antd";
import toast from "react-hot-toast";
import * as _ from "lodash";
import {
  BadgeCheck,
  Bridge3d,
  Check,
  Clock,
  Coins,
  DoubleCheck,
  HandCard,
  Mail,
  MapPin,
  MessageText,
  OpenNewWindow,
  PercentRotateOut,
  Phone,
  RefreshDouble,
  SimpleCart,
  User,
  XmarkCircle,
} from "iconoir-react";
import { formatPhoneNumber, formatCurrency } from "../../components/utils";
import validator from "validator";
import { MessageCircle } from "lucide-react";
var advancedFormat = require("dayjs/plugin/advancedFormat");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const OpenSubmission = () => {
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState(null);

  const navigate = useNavigate();
  const { submissionId } = useParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetSubmission(submissionId)
        .then((res) => {
          setSubmission(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Failed to load submission.");
          navigate(-1);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  let getStepType = (type) => {
    switch (type) {
      case "contact":
        return "Contact Information";
      case "address":
        return "Service Address";
      case "boolean":
        return "Yes/No Question";
      case "single-select":
        return "Single Choice Question";
      case "multi-select":
        return "Multiple Choice Question";
      case "custom":
        return "Custom Step";
      case "payment":
        return "Payment";
      case "addon":
        return "Addons / Upsells";
      case "info":
        return "Info Step";
      default:
        return "Unknown Step";
    }
  };

  const renderFields = (answer, step) => {
    if (step.stepType === "multi-select" || step.stepType === "single-select") {
      return (
        <>
          {step.fields[0].options.map((option) => {
            if (answer.answers[option.value]) {
              return (
                <div className="flex items-center justify-start gap-2 px-4 py-2 text-green-900 bg-green-200 rounded-lg">
                  <BadgeCheck className="w-5 h-5" strokeWidth={2} />
                  <p className="font-medium">{option.label}</p>
                </div>
              );
            }
          })}
        </>
      );
    } else if (step.stepType === "boolean") {
      if (answer.answers.answer) {
        return (
          <div className="flex items-center justify-start gap-2 px-4 py-2 text-green-900 bg-green-200 rounded-lg">
            <BadgeCheck className="w-5 h-5" strokeWidth={2} />
            <p className="font-medium">Yes</p>
          </div>
        );
      } else {
        return (
          <div className="flex items-center justify-start gap-2 px-4 py-2 text-red-900 bg-red-200 rounded-lg">
            <XmarkCircle className="w-5 h-5" strokeWidth={2} />
            <p className="font-medium">No</p>
          </div>
        );
      }
    } else if (step.stepType === "custom") {
      return (
        <div className="flex items-center justify-start gap-2 px-4 py-2 text-gray-900 bg-gray-200 rounded-lg">
          {step.fields[0].inputType === "text" || (step.fields[0].inputType === "textarea" && <MessageText className="w-5 h-5" strokeWidth={2} />)}
          <p className="font-medium">{answer.answers[step.fields[0].name]}</p>
        </div>
      );
    }
  };

  const renderAnswer = (answer, index) => {
    let steps = submission.widgetData.steps;
    let step = _.find(steps, { stepId: answer.stepId });
    if (step.stepType !== "contact" && step.stepType !== "info" && step.stepType !== "payment" && step.stepType !== "addon" && step.stepType !== "address") {
      return (
        <div className="w-full max-w-3xl px-8 py-6 bg-white border border-white shadow-md rounded-xl" key={answer.stepId}>
          <div className="flex flex-col items-center justify-start w-full gap-4 sm:flex">
            <div className="flex items-center justify-between w-full">
              <p className="text-lg font-bold leading-none text-black">{step.title}</p>
              <p className="text-sm font-normal text-gray-400">{getStepType(step.stepType)}</p>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2">{renderFields(answer, step)}</div>
          </div>
        </div>
      );
    }
  };

  const getAddress = () => {
    let address;
    let steps = submission.widgetData.steps;
    let addressStep = _.find(steps, { stepType: "address" });
    if (addressStep) {
      let addressData = _.find(submission.answers, { stepId: addressStep.stepId });
      if (addressData) {
        addressData = addressData.answers;
        let line1 = addressData.address;
        if (addressData.address2 && addressData.address2.length > 0) {
          line1 += `, ${addressData.address2}`;
        }
        line1 += `,`;
        let line2 = `${addressData.city}, ${addressData.state} ${addressData.zip}`;
        address = { line1, line2 };
      }
    }
    return address;
  };

  const renderPurchase = () => {
    if (submission.payment && submission.payment.paymentId && submission.payment.paymentId.length > 0 && submission.payment.amount > 0) {
      let baseFee = 0;
      let addonsTotal = 0;
      let paymentStep = _.find(submission.widgetData.steps, { stepType: "payment" });
      if (paymentStep) {
        baseFee = paymentStep.config.price;
        addonsTotal = submission.payment.amount - baseFee;
      }
      return (
        <div className="flex flex-col items-start justify-start w-full gap-4 px-8 py-6 bg-white shadow-md rounded-xl">
          <p className="mb-2 text-lg font-bold leading-none text-black">Payment Information</p>
          <div className="flex items-center justify-start w-full gap-2 cursor-pointer">
            <Clock className="w-4 h-4 text-black" strokeWidth={2} />
            <p className="text-sm font-medium leading-none text-gray-600">{dayjs(submission.payment.capturedOn).format("MMMM Do, YYYY [at] h:mm A")}</p>
          </div>
          <div className="flex items-center justify-start w-full gap-2 cursor-pointer">
            <HandCard className="w-4 h-4 text-black" strokeWidth={2} />
            <p className="text-sm font-medium leading-none text-gray-600">Processed by {submission.payment.paymentProcessor}</p>
          </div>
          <div className="flex items-center justify-start w-full gap-2 cursor-pointer">
            <Coins className="w-4 h-4 text-black" strokeWidth={2} />
            <p className="text-sm font-medium leading-none text-gray-600">Price: {formatCurrency(baseFee)}</p>
          </div>
          <div className="flex items-center justify-start w-full gap-2 cursor-pointer">
            <SimpleCart className="w-4 h-4 text-black" strokeWidth={2} />
            <p className="text-sm font-medium leading-none text-gray-600">Addons: {formatCurrency(addonsTotal)}</p>
          </div>
          <div className="flex items-center justify-start w-full gap-2 cursor-pointer">
            <DoubleCheck className="w-4 h-4 text-black" strokeWidth={2} />
            <p className="text-sm font-medium leading-none text-gray-600">Total: {formatCurrency(submission.payment.amount)}</p>
          </div>
          <div className="flex items-center justify-start w-full gap-2 cursor-pointer">
            <PercentRotateOut className="w-4 h-4 text-black" strokeWidth={2} />
            <p className="text-sm font-medium leading-none text-gray-600">
              {paymentStep === null ? "Unknown" : paymentStep.config.passFees ? "Fees are passed on to your customer" : "Fees are absorbed by your business"}
            </p>
          </div>
        </div>
      );
    }
  };

  const renderAddons = () => {
    if (submission.payment && submission.payment.paymentId && submission.payment.paymentId.length > 0 && submission.payment.amount > 0) {
      let addonStep = _.find(submission.widgetData.steps, { stepType: "addon" });
      if (addonStep) {
        let addons = _.find(submission.answers, { stepId: addonStep.stepId });
        if (addons) {
          addons = addons.answers;
          let purchased = [];
          for (let i = 0; i < addonStep.fields[0].options.length; i++) {
            let el = addonStep.fields[0].options[i];
            if (addons[el.optionId] === true) {
              purchased.push(el);
            }
          }
          if (purchased.length > 0) {
            let totalUpsells = 0;
            for (let i = 0; i < purchased.length; i++) {
              totalUpsells += purchased[i].price;
            }
            return (
              <div className="flex flex-col items-start justify-start w-full gap-6 px-8 py-6 bg-white shadow-md rounded-xl">
                <p className="mb-2 text-lg font-bold leading-none text-black">Addons Purchased</p>
                {purchased.map((upsell) => (
                  <div className="flex items-center justify-between w-full gap-2">
                    <div className="flex items-center justify-start flex-grow gap-2">
                      <SimpleCart className="w-4 h-4 text-black" strokeWidth={2} />
                      <p className="text-sm font-medium leading-none text-gray-600 truncate">{upsell.label}</p>
                    </div>
                    <p className="text-sm font-medium leading-none text-black shrink-0">{formatCurrency(upsell.price)}</p>
                  </div>
                ))}
                <div className="flex items-center justify-end w-full gap-2 pt-4 border-t border-gray-200">
                  <p className="text-sm font-semibold leading-none text-black shrink-0">{formatCurrency(totalUpsells)}</p>
                </div>
              </div>
            );
          }
        }
      }
    }
  };

  let getStatus = () => {
    if (submission.completed) {
      return (
        <div className="flex justify-center items-center gap-1 rounded-lg bg-green-500 py-1.5 px-3">
          <Check className="w-4 h-4 text-white" strokeWidth={2} />
          <p className="text-[0.65rem] font-bold uppercase leading-[100%] text-white">Completed</p>
        </div>
      );
    } else if (dayjs(submission.startedOn).isBefore(dayjs().subtract(30, "minutes"))) {
      return (
        <div className="flex justify-center items-center gap-1 rounded-lg bg-red-600 py-1.5 px-3">
          <OpenNewWindow className="w-4 h-4 text-white -rotate-90" />
          <p className="text-[0.65rem] font-bold uppercase leading-[100%] text-white">Abandoned</p>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center items-center gap-1 rounded-lg bg-blue-600 py-1.5 px-3">
          <RefreshDouble className="w-4 h-4 text-white" />
          <p className="text-[0.65rem] font-bold uppercase leading-[100%] text-white">Abandoned</p>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full min-h-screen pl-4 overflow-y-auto sm:pl-6 md:pl-8 lg:pl-64">
      <div className="flex flex-row items-center justify-between w-full px-8 py-4 border-b border-gray-300">
        <p className="text-lg font-bold text-black">Submission</p>
        <Button type="default" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
      {!loading ? (
        <>
          <div className="flex flex-row items-center justify-between w-full max-w-6xl py-5 mt-4">
            <div className="flex flex-col items-start justify-center gap-2">
              <p className="text-2xl font-bold leading-none text-black">
                {submission.leadData.name && submission.leadData.name.length > 0 ? submission.leadData.name : "Lead"}
              </p>
              <p className="text-sm font-medium leading-none text-gray-500">{getStatus()}</p>
            </div>
            <div className="flex items-center justify-end gap-2">
              <Popover placement="bottomRight" content="Start a phone call">
                <Button
                  shape="circle"
                  icon={<Phone className="w-4 h-4" />}
                  onClick={() => window.open(`tel:${submission.leadData.phone}`, "_blank")}
                  className="flex items-center justify-center"
                  size="large"
                />
              </Popover>
              {submission.leadData.email && validator.isEmail(submission.leadData.email) && (
                <Popover placement="bottomRight" content="Send an email">
                  <Button
                    shape="circle"
                    icon={<Mail className="w-4 h-4" />}
                    onClick={() => window.open(`mailto:${submission.leadData.email}`, "_blank")}
                    className="flex items-center justify-center"
                    size="large"
                  />
                </Popover>
              )}
              <Popover placement="bottomRight" content="Text and interact">
                <Button
                  shape="circle"
                  icon={<MessageCircle className="w-4 h-4" />}
                  onClick={() => navigate(`/messages/${submission.leadId}`)}
                  className="flex items-center justify-center"
                  size="large"
                />
              </Popover>
            </div>
          </div>
          <div className="flex items-start justify-center w-full max-w-6xl gap-10 py-2">
            <div className="flex flex-col items-start justify-start flex-grow gap-4">
              {submission.answers.map((answer, index) => renderAnswer(answer, index))}
            </div>
            <div className="flex flex-col items-center justify-start w-full max-w-sm gap-5 h-fit">
              <div className="flex flex-col items-start justify-start w-full gap-4 px-8 py-6 bg-white shadow-md rounded-xl">
                <div className="flex items-center justify-start w-full gap-4 mb-2">
                  <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200/60">
                    <User className="w-6 h-6 text-black" strokeWidth={2} />
                  </div>
                  <div className="flex flex-col items-start justify-center gap-1">
                    <p className="text-base font-semibold leading-none text-black">
                      {submission.leadData.name && submission.leadData.name.length > 0 ? submission.leadData.name : "Lead"}
                    </p>
                    <p className="text-xs font-medium leading-none text-gray-400">{dayjs(submission.leadData.leadCreated).format("MMMM Do, YYYY")}</p>
                    <p className="text-xs font-medium leading-none text-gray-400">Created {dayjs(submission.leadData.leadCreated).fromNow()}</p>
                  </div>
                </div>
                {submission.leadData.phone && submission.leadData.phone.length === 10 && (
                  <div
                    className="flex items-center justify-start w-full gap-2 px-2 cursor-pointer"
                    onClick={() => window.open(`tel:${submission.leadData.phone}`, "_blank")}
                  >
                    <Phone className="w-4 h-4 text-black" strokeWidth={2} />
                    <p className="text-sm font-medium leading-none text-gray-600">{formatPhoneNumber(submission.leadData.phone)}</p>
                  </div>
                )}
                {submission.leadData.email && validator.isEmail(submission.leadData.email) && (
                  <div
                    className="flex items-center justify-start w-full gap-2 px-2 cursor-pointer"
                    onClick={() => window.open(`mailto:${submission.leadData.email}`, "_blank")}
                  >
                    <Mail className="w-4 h-4 text-black" strokeWidth={2} />
                    <p className="text-sm font-medium leading-none text-gray-600">{submission.leadData.email}</p>
                  </div>
                )}
                {getAddress() && getAddress().line1 && getAddress().line2 && getAddress().line1.length > 0 && getAddress().line2.length > 0 && (
                  <div className="flex items-center justify-start w-full gap-2 px-2">
                    <MapPin className="w-4 h-4 text-black" strokeWidth={2} />
                    <div className="flex flex-col items-start justify-center gap-1">
                      <p className="text-sm font-medium leading-none text-gray-600">{getAddress().line1}</p>
                      <p className="text-sm font-medium leading-none text-gray-600">{getAddress().line2}</p>
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-start w-full gap-2 px-2">
                  <Clock className="w-4 h-4 text-black" strokeWidth={2} />
                  <p className="text-sm font-medium leading-none text-gray-600">Submitted {dayjs(submission.startedOn).fromNow()}</p>
                </div>
                <div className="flex items-center justify-start w-full gap-2 px-2">
                  <Bridge3d className="w-4 h-4 text-black" strokeWidth={2} />
                  <p className="text-sm font-medium leading-none text-gray-600">
                    {((submission.answers.length / submission.widgetData.steps.length) * 100).toFixed(0)}% Complete
                  </p>
                </div>
              </div>
              {renderPurchase()}
              {renderAddons()}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-10">
          <p className="font-medium text-gray-500 uppercase animate-pulse">Loading Submission...</p>
        </div>
      )}
    </div>
  );
};

export default OpenSubmission;
