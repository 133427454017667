import { useState, useEffect } from "react";
import { Button, Input } from "antd";
import LogoShape from "../../assets/shape.png";
import Logo from "../../assets/logo";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import FunnelLoading from "../../assets/funnelLoading.json";
import { toast } from "react-hot-toast";
import { SignIn, GetUser } from "../../actions/auth";
import validator from "validator";

const Login = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      GetUser()
        .then((response) => {
          authDispatch({
            type: "valid-login",
            payload: {
              token: localStorage.getItem("auth-token"),
              role: response.data.role,
              user: response.data,
            },
          });
          toast.error("You're already logged in, redirecting to dashboard");
          setTimeout(() => navigate("/"), 700);
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const submitLogin = () => {
    if (!validator.isEmail(email)) {
      toast.error("Please enter a valid email address");
    } else if (!password.length > 6) {
      toast.error("Please enter a valid password");
    } else {
      setLoading(true);
      SignIn({ email, password })
        .then((res) => {
          let payload = {
            token: res.data.token,
            email: email,
            user: res.data.user,
          };
          localStorage.setItem("auth-token", res.data.token);
          authDispatch({
            type: "valid-login",
            payload: payload,
          });
          toast.success("Logged in successfully!");
          navigate("/", { replace: true });
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <div className="h-screen md:flex">
      <div className="relative items-center justify-around hidden w-1/2 overflow-hidden md:flex bg-pattern">
        <div className="w-full px-20">
          <p className="mt-1 text-6xl text-white font-body">Turn your website into a conversation starter</p>
          <p className="mt-1 text-2xl font-light text-white font-body">Capture Leads. Close at a higher rate</p>
        </div>
        <div className="absolute border-4 border-t-8 rounded-full -bottom-32 -left-40 w-80 h-80 border-opacity-30"></div>
        <div className="absolute border-4 border-t-8 rounded-full -bottom-40 -left-20 w-80 h-80 border-opacity-30"></div>
        <div className="absolute border-4 border-t-8 rounded-full -top-40 -right-0 w-80 h-80 border-opacity-30"></div>
        <div className="absolute border-4 border-t-8 rounded-full -top-20 -right-20 w-80 h-80 border-opacity-30"></div>
      </div>
      <div className="flex items-center justify-center py-10 bg-white md:w-1/2">
        <div className="flex flex-col items-start justify-start w-full gap-2 px-20">
          {!loading ? (
            <>
              <div className="flex flex-row items-center justify-start gap-2 cursor-pointer mb-7" onClick={() => window.open("https://hyperook.com", "_blank")}>
                <Logo className="w-auto h-10" />
              </div>
              <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
              <Input.Password placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
              <div className="flex flex-row items-center justify-between w-full mt-3">
                <p className="text-sm cursor-pointer hover:text-blue-500 text-slate-500" onClick={() => navigate("/forgotPassword")}>
                  Forgot Password ?
                </p>
                <Button
                  type="primary"
                  className="flex items-center justify-center px-20 py-5 font-semibold text-white uppercase bg-blue-900"
                  onClick={() => submitLogin()}
                >
                  Login
                </Button>
              </div>
              <div className="flex flex-row items-center justify-center w-full mt-10">
                <p className="text-xs cursor-pointer text-slate-400" onClick={() => navigate("/register")}>
                  Don't have an account ? <span className="font-semibold text-blue-500/80 hover:text-blue-500">Sign Up</span>
                </p>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center w-full gap-4">
              <Player src={FunnelLoading} autoplay loop className="w-20 h-20" />
              <p className="text-sm font-light tracking-widest uppercase font-body animate-pulse">Loading</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
